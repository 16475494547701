import { Injectable } from '@angular/core';
import { Cart, CartAWB, PaymentCart, PaymentMethod, PaymentMethodsType, Threshold } from '@cargos/sprintpay-models';
import {
    BehaviorSubject,
    Observable,
    catchError,
    combineLatest,
    defaultIfEmpty,
    distinctUntilChanged,
    finalize,
    forkJoin,
    map,
    of,
    switchMap,
    take,
    throwError,
} from 'rxjs';
import { promoCodeValue } from 'src/app/utils/constants';
import { PromoCodeByAWBService } from '../../promo-code.service';
import { CartAPIService } from '../../requests/cart-api.service';
import { ALL_FEES_LIST } from './models/types';

@Injectable({
    providedIn: 'root',
})
export class CartBillService {
    private cartBill = new BehaviorSubject<Cart | null>(null);
    private isCartLoading = new BehaviorSubject<boolean>(false);

    constructor(
        private cartApiService: CartAPIService,
        private promoCodeService: PromoCodeByAWBService
    ) {}

    /**
     * @method getCartRequestAndSetCart()
     * @param (method?: PaymentMethodsType)
     * @description Get the cart bill v2 and set the cart bill v2 value
     * @returns Observable<Cart>
     */
    getCartRequestAndSetCart(
        paymentMethod?: PaymentMethodsType | undefined,
        thresholdPaymentMethod?: PaymentMethodsType | undefined,
        additionalFee?: number
    ): Observable<Cart> {
        this.setIsCartLoading(true);
        const componentNamePaymentMethod = paymentMethod
            ? PaymentMethod.getComponentNameByType(paymentMethod)
            : undefined;
        const componentNameThresholdPaymentMethod = thresholdPaymentMethod
            ? PaymentMethod.getComponentNameByType(thresholdPaymentMethod)
            : undefined;

        return this.cartApiService
            .getCartBill(componentNamePaymentMethod, componentNameThresholdPaymentMethod, additionalFee)
            .pipe(
                take(1),
                map((cart: Cart) => {
                    this.setCartBill(cart);
                    this.setIsCartLoading(false);
                    return cart;
                }),
                catchError((error) => {
                    this.setIsCartLoading(false);
                    return throwError(() => error);
                }),
                finalize(() => {
                    this.setIsCartLoading(false);
                })
            );
    }

    /**
     * @method setCartBillV2()
     * @param (cart: Cart | null)
     * @description Set the cart bill v2 value
     */
    setCartBill(cart: Cart | null): void {
        this.cartBill.next(cart);
    }

    /**
     * @method getCartBillV2$()
     * @description Get the cart bill v2 value as an observable
     * @returns Observable<Cart | null>
     */
    getCartBill$(): Observable<Cart | null> {
        return this.cartBill.asObservable();
    }

    get instant_cart_bill(): Cart | null {
        return this.cartBill.value;
    }

    /**
     * @method getCartV2$()
     * @description This method retrieves the items in the cart
     * @returns Observable<PaymentCart[]> - The items in the cart.
     */
    getCartPaymentRequests$(aboveThreshold?: boolean): Observable<PaymentCart[]> {
        return this.getPaymentRequestsGroupByAWB$().pipe(
            map((paymentRequests: CartAWB[]) => {
                if (paymentRequests) {
                    return paymentRequests.map((cartBillResponse: CartAWB) => cartBillResponse.paymentRequest).flat();
                }

                return [];
            }),
            map((paymentRequests) => {
                return paymentRequests.filter((paymentRequest: PaymentCart) => {
                    if (aboveThreshold === true) {
                        return paymentRequest.isAboveThreshold;
                    }
                    if (aboveThreshold === false) {
                        return !paymentRequest.isAboveThreshold;
                    }

                    return true;
                });
            })
        );
    }

    getCartPaymentRequestWithouteModal$(aboveThreshold?: boolean): Observable<PaymentCart[]> {
        return this.getPaymentRequestsGroupByAWB$().pipe(
            map((paymentRequests: CartAWB[]) => {
                if (paymentRequests) {
                    return paymentRequests.map((cartBillResponse: CartAWB) => cartBillResponse.paymentRequest).flat();
                }

                return [];
            }),
            map((paymentRequests: PaymentCart[]) =>
                paymentRequests.filter((paymentRequest) => paymentRequest.facility?.paidTo?.toLowerCase() !== 'emodal')
            ),
            map((paymentRequests) => {
                return paymentRequests.filter((paymentRequest: PaymentCart) => {
                    if (aboveThreshold === true) {
                        return paymentRequest.isAboveThreshold;
                    }
                    if (aboveThreshold === false) {
                        return !paymentRequest.isAboveThreshold;
                    }

                    return true;
                });
            })
        );
    }

    getCartPaymentRequesteModal$(aboveThreshold?: boolean): Observable<CartAWB[]> {
        return this.getPaymentRequestsGroupByAWB$().pipe(
            map((cartBillResponseDTOList: CartAWB[]) => {
                if (!cartBillResponseDTOList) {
                    return [];
                }
                const filteredCartAWB: CartAWB[] = cartBillResponseDTOList.filter((cartBillResponse: CartAWB) =>
                    this.paymentCartIsEModal(cartBillResponse)
                );
                return filteredCartAWB
                    .filter((cartAWB: CartAWB) => {
                        return cartAWB.paymentRequest.find((paymentRequest: PaymentCart) => {
                            if (aboveThreshold === true) {
                                return !!paymentRequest.isAboveThreshold;
                            }
                            if (aboveThreshold === false) {
                                return !paymentRequest.isAboveThreshold;
                            }
                            return [];
                        });
                    })
                    .flat();
            })
        );
    }

    paymentCartIsEModal(cartBillResponse: CartAWB): boolean {
        return cartBillResponse.paymentRequest.every((paymetRequest) =>
            paymetRequest.paidTo?.toLowerCase().includes('emodal')
        );
    }

    getCartPaymentRequests(aboveThreshold?: boolean): PaymentCart[] {
        return this.instant_payment_requests_group_by_awb
            .map((cartAWB: CartAWB) => cartAWB.paymentRequest)
            .flat()
            .filter((paymentRequest: PaymentCart) => {
                if (aboveThreshold === true) {
                    return paymentRequest.isAboveThreshold;
                }
                if (aboveThreshold === false) {
                    return !paymentRequest.isAboveThreshold;
                }

                return true;
            });
    }

    getPaymentRequestsGroupByAWB$(): Observable<CartAWB[]> {
        return this.getCartBill$().pipe(
            map((cart: Cart | null) => {
                if (cart) {
                    return cart?.cartBillResponseDTOList;
                }

                return [];
            })
        );
    }

    get instant_payment_requests_group_by_awb(): CartAWB[] {
        return this.instant_cart_bill?.cartBillResponseDTOList || [];
    }

    getPaymentRequestsByAWB$(awb: string): Observable<PaymentCart[]> {
        return this.getPaymentRequestsGroupByAWB$().pipe(
            map((cart: CartAWB[]) => cart?.find((cartAWB: CartAWB) => cartAWB.awb === awb)?.paymentRequest || [])
        );
    }

    getPaymentRequestsByAWB(awb: string): PaymentCart[] {
        return (
            this.instant_payment_requests_group_by_awb.find((cartAWB: CartAWB) => cartAWB.awb === awb)
                ?.paymentRequest || []
        );
    }

    getLengthCart$(): Observable<number> {
        return this.getCartPaymentRequests$().pipe(map((paymentCarts) => paymentCarts.length));
    }

    getCartInvoices$(): Observable<PaymentCart[]> {
        return this.getCartPaymentRequests$().pipe(
            map((paymentCarts) => {
                return paymentCarts.filter((paymentCart: PaymentCart) => paymentCart.originalInvoice);
            })
        );
    }

    isThereInvoicesInCart$(): Observable<boolean> {
        return this.getCartInvoices$().pipe(
            map((paymentCarts) => !!paymentCarts.length),
            distinctUntilChanged()
        );
    }

    isTherePaymentsRequestsNoInvoices$(): Observable<boolean> {
        return this.getPaymentsRequestsNoInvoices$().pipe(
            map((paymentCarts) => !!paymentCarts.length),
            distinctUntilChanged()
        );
    }

    getPaymentsRequestsNoInvoices$(): Observable<PaymentCart[]> {
        return this.getCartPaymentRequests$().pipe(
            map((paymentCarts) => {
                return paymentCarts.filter((paymentCart: PaymentCart) => !paymentCart.originalInvoice);
            })
        );
    }

    getPaymentsRequestsNoInvoices(aboveThreshold?: boolean): PaymentCart[] {
        return this.getCartPaymentRequests(aboveThreshold).filter(
            (paymentCart: PaymentCart) => !paymentCart.originalInvoice
        );
    }

    isTherePaymentsRequestsNoInvoices(): boolean {
        return !!this.getPaymentsRequestsNoInvoices().length;
    }

    getCartInvoices(aboveThreshold?: boolean): PaymentCart[] {
        return this.getCartPaymentRequests(aboveThreshold).filter(
            (paymentCart: PaymentCart) => paymentCart.originalInvoice
        );
    }

    isThereInvoicesInCart(): boolean {
        return !!this.getCartInvoices().length;
    }

    private setIsCartLoading(isLoading: boolean): void {
        this.isCartLoading.next(isLoading);
    }

    getIsCartLoading$(): Observable<boolean> {
        return this.isCartLoading.asObservable();
    }

    getPaymentRequestsByAwbLookupData(aboveThreshold?: boolean): PaymentCart[] {
        return this.getCartPaymentRequests(aboveThreshold)
            .filter((item) => item.facility?.awbLookupData)
            .map((externalPayment: PaymentCart) => externalPayment);
    }

    getNonReservationFeeTotal(): Observable<number | undefined> {
        return this.getCartBill$().pipe(
            map((cartBill: Cart | null) => {
                let nonReservationFeeTotal = cartBill?.nonReservationFee || 0;
                if (cartBill?.threshold) {
                    nonReservationFeeTotal += cartBill?.threshold?.nonReservationFee || 0;
                }

                return nonReservationFeeTotal;
            })
        );
    }

    getNonReservationFee$(): Observable<number> {
        return combineLatest([this.getNonReservationFeeTotal(), this.promoCodeService.getAllPromoCodeApplied$()]).pipe(
            map(([nonReservationFeeTotal, promoCodeApplied]) => {
                const totalPromoCode = promoCodeApplied.length * promoCodeValue;
                return (nonReservationFeeTotal || 0) - totalPromoCode;
            }),
            distinctUntilChanged()
        );
    }

    /**
     * @method deleteAndGetCart()
     * @description Delete the cart and update the cart bill value
     * @returns Observable<string> - A string indicating the completion status
     */
    deleteAndGetCart(): Observable<string> {
        return this.cartApiService.deleteCart().pipe(
            map(() => {
                this.setCartBill(null);

                return 'Completed';
            })
        );
    }

    /**
     * @method isThereItemsInCart()
     * @description Check if there are any items in the cart
     * @returns boolean - True if there are items in the cart, false otherwise
     */
    isThereItemsInCart(): boolean {
        return !!this.getCartPaymentRequests().length;
    }

    hasAGIPayments(): boolean {
        return !!this.getCartPaymentRequests().some(
            (paymentCart: PaymentCart) => paymentCart?.externalData?.source?.toLowerCase() === 'alliance'
        );
    }

    getSubTotal$(): Observable<number> {
        if (!this.instant_cart_bill?.subTotal) {
            return of(this.calculateSubtotal());
        }
        return this.getCartBill$().pipe(map((cart: Cart | null) => cart?.subTotal || 0));
    }

    getSubtotal(): number {
        if (!this.instant_cart_bill?.subTotal) {
            return this.calculateSubtotal();
        }
        return this.instant_cart_bill?.subTotal || 0;
    }

    getTotal(): number {
        return this.instant_cart_bill?.total || 0;
    }

    private calculateSubtotal(): number {
        return this.getCartPaymentRequests().reduce((acc: number, paymentCart: PaymentCart) => {
            return acc + (paymentCart.amount || 0);
        }, 0);
    }

    /**
     * @method deletePaymentRequestById()
     * @param (id?: request id)
     * @description Delete payment request by id
     * @returns Observable<PaymentCart[]>
     */
    deletePaymentRequestById(id: number): Observable<PaymentCart[]> {
        this.setIsCartLoading(true);
        return this.cartApiService.deletePaymentRequestById(id).pipe(
            take(1),
            finalize(() => {
                this.setIsCartLoading(false);
            })
        );
    }

    deletePaymentRequests(ids: number[]): Observable<PaymentCart[]> {
        this.setIsCartLoading(true);
        return this.cartApiService.deletePaymentRequests(ids).pipe(
            take(1),
            finalize(() => {
                this.setIsCartLoading(false);
            })
        );
    }

    getPaymentCartById$(id: number): Observable<PaymentCart | undefined> {
        return this.getCartPaymentRequests$().pipe(
            map((cart: PaymentCart[]) => cart.find((paymentCart: PaymentCart) => paymentCart.id === id))
        );
    }

    getMessageThreshold(): Observable<Threshold | null> {
        return this.getCartBill$().pipe(
            map((cartBill: Cart | null) => {
                return cartBill?.threshold || null;
            })
        );
    }

    isCartAboveThreshold$(): Observable<boolean> {
        return this.getCartPaymentRequests$().pipe(
            map((paymentRequests: PaymentCart[]) => {
                return paymentRequests.some((paymentRequest: PaymentCart) => paymentRequest.isAboveThreshold);
            })
        );
    }

    isCartAboveThreshold(): boolean {
        return this.getCartPaymentRequests().some((paymentRequest: PaymentCart) => paymentRequest.isAboveThreshold);
    }

    isCartBelowThreshold(): boolean {
        return this.getCartPaymentRequests().some((paymentRequest: PaymentCart) => !paymentRequest.isAboveThreshold);
    }

    isCartBelowThreshold$(): Observable<boolean> {
        return this.getCartPaymentRequests$().pipe(
            map((paymentRequests: PaymentCart[]) => {
                return paymentRequests.some((paymentRequest: PaymentCart) => !paymentRequest.isAboveThreshold);
            })
        );
    }

    getPromoCodeValueByAWB$(awb: string): Observable<number | undefined> {
        return this.getPaymentRequestsByAWB$(awb).pipe(
            map((paymentCarts: PaymentCart[]) => {
                return paymentCarts?.find((paymentCart) => paymentCart.nonReservationFee)?.nonReservationFee;
            })
        );
    }

    getPromoCodeValueByAWB(awb: string): number | undefined {
        return this.getPaymentRequestsByAWB(awb).find((paymentCart) => paymentCart.nonReservationFee)
            ?.nonReservationFee;
    }

    getFeaturesAmountByAWB(awb: string): Observable<{
        total: number;
        nonReservationFee: boolean;
        isAwbPaidWithPromoCode: boolean;
    }> {
        return combineLatest([this.getPromoCodeValueByAWB$(awb), this.getTotalByAWB(awb)]).pipe(
            switchMap(([nonReservationFee, totalAwb]) => {
                return this.promoCodeService.isAwbPaidWithPromoCode$(awb).pipe(
                    map((isAwbPaidWithPromoCode) => {
                        const total =
                            !!nonReservationFee && !isAwbPaidWithPromoCode
                                ? (totalAwb || 0) + promoCodeValue
                                : totalAwb || 0;
                        return {
                            total,
                            nonReservationFee: !!nonReservationFee,
                            isAwbPaidWithPromoCode,
                        };
                    })
                );
            })
        );
    }

    getTotalByAWB(awb: string): Observable<number | undefined> {
        return this.getCartBill$().pipe(
            map((cartBill) => cartBill?.cartBillResponseDTOList.find((request) => request.awb === awb)?.totalAwb)
        );
    }

    getTotalByThreshold$(aboveThreshold: boolean): Observable<number | null> {
        return this.getCartPaymentRequests$(aboveThreshold).pipe(
            switchMap((paymentsCart: PaymentCart[]) => {
                return forkJoin(paymentsCart.map((paymentCart: PaymentCart) => of(paymentCart.amount || 0)));
            }),
            defaultIfEmpty(null),
            map((allAmounts) => allAmounts?.reduce((acc, item) => acc + item, 0) || null)
        );
    }

    getFees$(paymentCart: PaymentCart): Observable<number> {
        let sum = 0;
        ALL_FEES_LIST.forEach((keyFee) => {
            const value = paymentCart[keyFee] || 0;
            sum += value;
        });

        return this.promoCodeService
            .isAwbPaidWithPromoCode$(paymentCart.awb || '')
            .pipe(
                map((isAwbPaidWithPromoCode) =>
                    !!paymentCart.nonReservationFee && !isAwbPaidWithPromoCode ? promoCodeValue + sum : sum
                )
            );
    }

    getFees(paymentCart: PaymentCart): number {
        let sum = 0;
        ALL_FEES_LIST.forEach((keyFee) => {
            const value = paymentCart[keyFee] || 0;
            sum += value;
        });
        const awbWithPromoCode = this.promoCodeService.isAwbPaidWithPromoCode(paymentCart.awb || '');
        return !!paymentCart.nonReservationFee && !awbWithPromoCode ? promoCodeValue + sum : sum;
    }

    getAWBsInCart(): string[] {
        return this.instant_payment_requests_group_by_awb.map((cartAWB: CartAWB) => cartAWB.awb);
    }

    resetCart(): void {
        this.setCartBill(null);
    }
}
