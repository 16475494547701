<!-- START: ANGULAR MATERIAL TABLE COMPONENT -->
<!-- START: FILTER WORKS ONLY IF TRUE IN COMPONENT CALL -->
@if(am_filter) {
<div class="row">
    <div
        [ngClass]="{ 'col-sm-12 col-md-12 col-lg-3' : am_refresh || am_addItem,'col-sm-12 col-md-12 col-lg-12' : !am_refresh && !am_addItem}"
    >
        <mat-form-field appearance="outline" class="w-100 mt-4" color="primary">
            <mat-label>Enter your search</mat-label>
            <input
                id="table-input-search"
                matInput
                [(ngModel)]="value"
                [formControl]="search"
                (keyup.enter)="applyFilter($event)"
                data-cy="table_inputFilter"
                placeholder="Type your search here"
            />
            <button
                id="table-close-search-button"
                *ngIf="value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                data-cy="table_inputFilterClear"
                (click)="clearSearch()"
            >
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>

    <!-- START: FORM -->
    <ng-container *ngIf="am_datePicker">
        <div class="col-sm-12 col-md-12 col-lg-3">
            <form [formGroup]="range">
                <mat-form-field appearance="outline" class="w-100 mt-4">
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [rangePicker]="picker" [max]="maxDate" [min]="minDate">
                        <input
                            id="from-date-range"
                            matStartDate
                            formControlName="start"
                            placeholder="From"
                            (dateChange)="onStartDateChanged('change', $event)"
                        />
                        <input matEndDate formControlName="end" placeholder="To" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker
                        #picker
                        [calendarHeaderComponent]="calendarHeader"
                        (closed)="onDateChanged()"
                    >
                    </mat-date-range-picker>
                </mat-form-field>
            </form>
        </div>
    </ng-container>
    <!-- END: FORM -->

    <ng-container *ngIf="am_statusFilter">
        <div class="col-sm-12 col-md-12 col-lg-2">
            <mat-form-field appearance="outline" class="w-100 mt-4">
                <mat-label>Update status filter</mat-label>
                <mat-select
                    id="status-filter"
                    #statusesSelect
                    [value]="am_statusFilter"
                    multiple
                    panelClass="status-filter"
                    (openedChange)="revertStatusFilterSelection($event)"
                >
                    <mat-select-trigger>
                        <span *ngIf="am_statusFilter.length > 1"> {{ am_statusFilter.length }} selected </span>
                        <span *ngIf="am_statusFilter.length === 1">
                            {{ getDescriptionForStatus(am_statusFilter[0]) }}
                        </span>
                    </mat-select-trigger>
                    <div class="status-filter-toggle">
                        <mat-checkbox
                            id="status-select-deselect"
                            color="primary"
                            [checked]="statusesSelect.value.length === customStatusesArray.length"
                            [indeterminate]="statusesSelect.value.length > 0 && statusesSelect.value.length < customStatusesArray.length"
                            (change)="toggleStatusFilterSelection($event.checked)"
                        >
                            @if(statusesSelect.value.length === customStatusesArray.length){ Deselect All } @else {
                            Select All }
                        </mat-checkbox>
                    </div>
                    <ng-container *ngFor="let status of customStatusesArray; let i = index">
                        <mat-option [id]="i.toString()" [value]="status">
                            {{ getDescriptionForStatus(status) || 'Description not found' }}
                        </mat-option>
                    </ng-container>
                    <button
                        id="update-filter-button"
                        mat-button
                        class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green status-update-btn"
                        (click)="applyStatuses()"
                    >
                        Update
                    </button>
                </mat-select>
            </mat-form-field>
        </div>
    </ng-container>

    <ng-container *ngIf="am_refresh || am_refund">
        <div class="col-sm-12 col-md-12" [ngClass]="(am_datePicker === true ? 'col-lg-4' : 'col-lg-6')">
            <div class="refresh-btn-wrapper d-block d-md-flex justify-content-end">
                <button
                    id="create-refund-request-button"
                    *ngIf="am_refund"
                    mat-fab-button
                    type="button"
                    color="primary"
                    (click)="goToPaymentHistory()"
                    class="d-block text-uppercase btn__sprintpay btn__sprintpay--outlineDefault btn__actions--refund ms-0 ms-md-2 mb-2 mb-md-0"
                >
                    <div class="align-center">
                        <span class="material-symbols-outlined me-2">monetization_on</span>Create Refund Request
                    </div>
                </button>
                <button
                    id="refund-refresh-button"
                    mat-stroked-button
                    id="refresh"
                    type="button"
                    color="primary"
                    (click)="applyRefresh()"
                    class="align-center d-block text-uppercase btn__sprintpay btn__sprintpay--outlineDefault btn__actions--refund ms-0 ms-md-2 mb-2 mb-md-0"
                >
                    <div class="align-center"><span class="material-symbols-outlined me-2">sync</span>Refresh</div>
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="am_addItem">
        <div class="col-12 col-lg-3 align-self-center ms-auto me-0 text-end">
            <button id="table-add-item" mat-button color="primary" (click)="onAddItem()">
                <mat-icon>add</mat-icon> {{am_addItem}}
            </button>
        </div>
    </ng-container>
</div>
}
<!-- END: FILTER WORKS ONLY IF TRUE IN COMPONENT CALL -->
<!-- START: DOWNLOAD BAR WORKS ONLY IF TRUE IN COMPONENT CALL -->
@if(am_downloadbar || am_generateFile){
<div class="row">
    <div class="col-12">
        <mat-toolbar class="download__toolbar h-auto d-flex flex-column flex-lg-row justify-content-end mb-3">
            <span class="text-uppercase lh-base py-3">{{this.selection.selected.length}} Item(s) Selected</span>
            <div *ngIf="am_generateFile">
                <button
                    id="generate-file-button"
                    mat-button
                    type="button"
                    color="primary"
                    (click)="generateFile()"
                    class="text-uppercase btn__sprintpay btn__sprintpay--link d-block p-0 pay-with-bank-debit"
                    [disabled]="am_disableGenerateFile"
                >
                    Pay with Bank debit
                </button>
            </div>
            <div *ngIf="am_showRedirectToBankAccount">
                <button
                    id="add-bank-account-button"
                    mat-button
                    type="button"
                    (click)="redirectToBankDebit()"
                    class="text-uppercase btn__sprintpay btn__sprintpay--link d-block p-0"
                >
                    Add Bank Account
                </button>
            </div>
            <button
                id="download-bar-button"
                *ngIf="am_downloadbar"
                mat-button
                color="primary"
                type="button"
                (click)="applyDownload()"
                class="text-uppercase btn__sprintpay btn__sprintpay--link d-block p-0"
            >
                Download
            </button>
        </mat-toolbar>
    </div>
</div>
}
<!-- END: DOWNLOAD BAR WORKS ONLY IF TRUE IN COMPONENT CALL -->
<!-- START: TABLE -->
@if(am_filterByColumn){
<div class="filter-icon d-flex justify-content-end mb-3">
    <img (click)="filterByColumnClick.emit()" src="assets/images/icons/icon-filter.svg" alt="filter icon" />
</div>
}
<div class="overflow-auto">
    <table
        mat-table
        [dataSource]="dataSource"
        class="w-100"
        matSort
        matSortDisableClear
        multiTemplateDataRows
        fixedLayout="{{fixedLayoutStatus}}"
    >
        <ng-container *ngFor="let column of am_columns; let i = index">
            <ng-container matColumnDef="{{column}}">
                <!-- START: CHECKBOX FOR EACH ROW -->
                <ng-container *ngIf="column === 'select'">
                    <th mat-header-cell *matHeaderCellDef class="checkbox-column">
                        <mat-checkbox
                            class="d-flex justify-content-start align-items-start ps-0"
                            [color]="'primary'"
                            (change)="$event ? masterToggle() : null"
                            [checked]="isAllSelected()"
                            [indeterminate]="currentPageHasSelection() && !isAllSelected()"
                            [aria-label]="checkboxLabel()"
                        ></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="toggleRowSelection(row)">
                        <mat-checkbox
                            #checkbox
                            class="checkbox-column d-flex justify-content-start align-items-start ps-0"
                            [color]="'primary'"
                            (click)="$event.stopPropagation()"
                            (change)="toggleRowSelection(row)"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)"
                            [disabled]="!!row?.disabledCheckbox"
                        ></mat-checkbox>
                    </td>
                </ng-container>
                <!-- END: CHECKBOX FOR EACH ROW -->
                <!-- START: SORTING WORKS ONLY IF TRUE IN COMPONENT CALL -->
                <ng-container *ngIf="am_sorting && hasSortable(am_columns[i]); else unSortableColumn">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        [disabled]="(column === 'button')"
                        class="p-3 fw-bold"
                    >
                        {{am_columnsName[i]}}
                    </th>
                </ng-container>
                <!-- END: SORTING WORKS ONLY IF TRUE IN COMPONENT CALL -->
                <!-- START: HIDE SORTING IF FALSE IN COMPONENT CALL -->
                <ng-template #unSortableColumn>
                    <th mat-header-cell *matHeaderCellDef class="p-3 fw-bold">{{am_columnsName[i]}}</th>
                </ng-template>
                <!-- END: HIDE SORTING IF FALSE IN COMPONENT CALL -->
                <!-- START: BUTTON FOR EACH ROW -->
                <ng-container *ngIf="column === 'button' || column === 'button-disabled'">
                    <td
                        mat-cell
                        *matCellDef="let element"
                        class="p-3"
                        [ngClass]="{'text-end' : (column === 'amount' || column === 'balanceDue')}"
                    >
                        <button
                            id="table-execute-function-btn"
                            *ngIf="element['button'] !== ''"
                            data-cy="table_executeBtnFunction"
                            mat-flat-button
                            color="primary"
                            type="button"
                            (click)="executeButtonFunction(element)"
                            class="btn__sprintpay btn__sprintpay--green px-1 py-0 mt-0 border-0 rounded-0 w-auto text-uppercase text-center executeBtnFunction"
                            [disabled]="column === 'button-disabled'"
                        >
                            {{element['button']}}
                        </button>
                    </td>
                </ng-container>
                <!-- END: BUTTON FOR EACH ROW -->
                <!-- START: DATA FOR EACH ROW -->
                <ng-container *ngIf="column === 'linkId'; else hasId">
                    <td
                        mat-cell
                        *matCellDef="let element"
                        class="p-3"
                        [ngClass]="{'text-end' : (column === 'amount' || column === 'balanceDue')}"
                    >
                        <ng-container *ngIf="element['link']">
                            <!-- POSSIBLE RESPONSIVE DESIGN FOR HEADERS><span class="mobile-label" *ngIf="am_columnsName[i] !== ''">{{am_columnsName[i]}}:</span><-->
                            <ng-container *ngIf="am_linkEnabled; else linkDisabled">
                                <a
                                    id="table-link"
                                    routerLink="{{element['link']}}"
                                    *ngIf="column === 'linkId'"
                                    data-cy="table_anchor_element"
                                    class="text-decoration-none"
                                    >{{element['linkId']}}</a
                                >
                            </ng-container>
                            <ng-template #linkDisabled>
                                <p data-cy="table_anchor_element" class="mb-0" *ngIf="column === 'linkId'">
                                    {{element['linkId']}}
                                </p>
                            </ng-template>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-template #hasId>
                    <ng-container *ngIf="column === 'id'">
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="p-3"
                            [ngClass]="{'text-end' : (column === 'amount' || column === 'balanceDue')}"
                        >
                            <ng-container *ngIf="element['link']">
                                <!-- POSSIBLE RESPONSIVE DESIGN FOR HEADERS><span class="mobile-label" *ngIf="am_columnsName[i] !== ''">{{am_columnsName[i]}}:</span><-->
                                <a
                                    id="table-link-by-id"
                                    routerLink="{{element['link']}}"
                                    *ngIf="column === 'id'"
                                    data-cy="table_anchor_element"
                                    class="text-decoration-none"
                                    >{{element['id']}}</a
                                >
                            </ng-container>
                            <ng-container *ngIf="!element['link']">
                                <!-- POSSIBLE RESPONSIVE DESIGN FOR HEADERS><span class="mobile-label" *ngIf="am_columnsName[i] !== ''">{{am_columnsName[i]}}:</span><-->
                                {{element['id']}}
                            </ng-container>
                        </td>
                    </ng-container>
                </ng-template>

                <ng-container *ngIf="column === 'name'">
                    <td mat-cell *matCellDef="let element" class="p-3">
                        <ng-container *ngIf="element['link']">
                            <a
                                id="table-link-by-name"
                                routerLink="{{element['link']}}"
                                *ngIf="column === 'name'"
                                data-cy="table_anchor_element"
                                class="text-decoration-none"
                                >{{element['name']}}</a
                            >
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container *ngIf="column !== 'id' && column !== 'STATUS'">
                    <td mat-cell *matCellDef="let element" class="p-3">
                        <ng-container>
                            <!-- POSSIBLE RESPONSIVE DESIGN FOR HEADERS><span class="mobile-label" *ngIf="am_columnsName[i] !== ''">{{am_columnsName[i]}}:</span><-->
                            {{ element[column] | slice: 0:50}} {{ element[column]?.length > 50 ? '...' : ''}}
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container *ngIf="am_customStatusColumn && column === 'STATUS'">
                    <td mat-cell *matCellDef="let element" class="status-column p-3">
                        <ng-container *ngIf="getCustomStatusKey(element['STATUS']) !== 'none'">
                            <div class="d-flex align-items-center fw-bold {{element['STATUS']}}">
                                <app-refund-status [status]="element['STATUS']"></app-refund-status>
                            </div>
                        </ng-container>
                    </td>
                </ng-container>
                <!-- END: DATA FOR EACH ROW -->
            </ng-container>
        </ng-container>

        <!-- START: TOGGLE -->
        @if(am_toggle){
        <ng-container matColumnDef="toggle">
            <th mat-header-cell *matHeaderCellDef aria-label="row toggle">{{am_toggle.name}}</th>
            <td mat-cell *matCellDef="let element">
                <mat-slide-toggle
                    color="primary"
                    #toggle
                    [checked]="!element?.toggle"
                    (click)="onToggleClick($event, element)"
                >
                </mat-slide-toggle>
            </td>
        </ng-container>
        }
        <!-- END: TOGGLE -->

        <ng-container *ngIf="am_actionRefund" matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions" class="text-center">Request refund</th>
            <td mat-cell *matCellDef="let element">
                <app-refund-button
                    [actions]="am_actionRefund"
                    [record]="element"
                    (clickAction)="selectRecord($event)"
                ></app-refund-button>
            </td>
        </ng-container>

        <!-- START: DOWNLOAD AS EXCEL COLUMN FOR THE SUPER ACH PAYMENTS -->
        <ng-container *ngIf="am_superACHDownload" matColumnDef="downloadAsExcel">
            <th mat-header-cell *matHeaderCellDef class="text-center fw-bold" aria-label="row downloadAsExcel">
                Download
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="d-flex">
                    <button
                        id="table-download-excel"
                        mat-button
                        color="primary"
                        (click)="applyActionSuperACHDownload(element); $event.stopPropagation()"
                        class="w-100"
                    >
                        Download as Excel
                    </button>
                </div>
            </td>
        </ng-container>
        <!-- END: DOWNLOAD AS EXCEL COLUMN FOR THE SUPER ACH PAYMENTS -->

        <ng-container *ngIf="am_actions" matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
            <td mat-cell *matCellDef="let element">
                <app-actions-table
                    *ngIf="element.hasDownloadableContent"
                    [actions]="am_actions"
                    [record]="element"
                    (clickAction)="selectRecord($event)"
                ></app-actions-table>
            </td>
        </ng-container>
        <!-- START: TABLE ROW GENERATION -->
        <ng-container *ngIf="(am_actions && am_actions.length > 0) || am_superACHDownload || am_toggle">
            <tr mat-header-row *matHeaderRowDef="columnsName" class="h-auto"></tr>
            <tr
                mat-row
                *matRowDef="let element; columns: columnsName;"
                class="h-auto element-row"
                [ngClass]="element.overDue === 'almostDue' ? 'almostDue': element.overDue === 'overdue' ? 'overdue':''"
                [class.expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element"
            ></tr>
            <!--
      <ng-container *ngIf="(am_actions && am_actions.length > 0)">
        <tr mat-row *matRowDef="let element; let row; columns: ['expandedDetail'] " [ngClass]="element.overDue === 'almostDue' ? 'almostDue': element.overDue === 'overdue' ? 'overdue':''" class="detail-row"></tr>
      </ng-container>
      -->
        </ng-container>
        <ng-container *ngIf="!am_actions && !am_superACHDownload && !am_toggle">
            <tr mat-header-row *matHeaderRowDef="am_columns" class="h-auto"></tr>
            <tr mat-row *matRowDef="let row; columns: am_columns;" class="h-auto"></tr>
        </ng-container>
        <!-- END: TABLE ROW GENERATION -->
        <!-- START: FILTER RESULT IF NOT DATA FOUND -->
        <ng-container *ngIf="am_filter">
            <tr class="mat-row" *matNoDataRow>
                <ng-container *ngIf="!am_emptyTableContent; else emptyRef">
                    <td class="mat-mdc-cell text-center py-3" colspan="100%" [ngClass]="{'p-5': !am_emptyTableMessage}">
                        {{am_emptyTableMessage ? am_emptyTableMessage : 'No records found, Try a new search'}}
                    </td>
                </ng-container>
                <ng-template #emptyRef>
                    <td class="mat-mdc-cell text-center" colspan="100%">
                        <ng-content select="[emptyTableContent]"></ng-content>
                    </td>
                </ng-template>
            </tr>
        </ng-container>
        <!-- END: FILTER RESULT IF NOT DATA FOUND -->
    </table>
</div>
<!-- END: TABLE -->
<!-- START: PAGINATION WORKS ONLY IF TRUE IN COMPONENT CALL -->
<ng-container *ngIf="am_pagination">
    <div class="paginator mt-2">
        <mat-paginator
            id="table-paginator"
            [pageSizeOptions]="am_paginationSizes"
            [length]="am_totalRows"
            [pageIndex]="currentPage"
            [pageSize]="currentPageSize"
            (page)="pageChanged($event)"
            aria-label="Select page of users"
            [showFirstLastButtons]="true"
        ></mat-paginator>
    </div>
</ng-container>
<!-- END: PAGINATION WORKS ONLY IF TRUE IN COMPONENT CALL -->
<!-- START: RECORD INDICATORS -->
<ng-container *ngIf="recordIndicators && recordIndicators.length > 0">
    <div class="row">
        <div *ngFor=" let indicator of recordIndicators" class="col-6 col-md-4 col-lg-3 d-flex align-items-center">
            <div class="indicator__figure" [ngClass]="indicator.text === 'Almost due'? 'almostDue': 'overdue'"></div>
            <label class="indicator__text">{{ indicator.text }}</label>
        </div>
    </div>
</ng-container>
<!-- END: RECORD INDICATORS -->
<!-- END: ANGULAR MATERIAL TABLE COMPONENT -->
