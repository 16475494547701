<div class="d-flex justify-content-start">
    <button id="table-actions" mat-icon-button [matMenuTriggerFor]="menu" aria-label="test">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
        <ng-container *ngFor="let action of am_actions; let i=index">
            <button
                id="table-actions-item"
                *ngIf="evalAction(rowRecord, action)"
                (click)="selectedAction(action); $event.stopPropagation()"
                mat-menu-item
                style="cursor: 'pointer'"
            >
                <mat-icon class="material-symbols-outlined me-2"> {{ action.icon }} </mat-icon>
                <span class="fw-normal">{{action.title}}</span>
            </button>
        </ng-container>
    </mat-menu>
</div>
