import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { Payment } from 'src/app/models/payments/payment.model';
import { DynamicFieldsService } from 'src/app/services/dynamic-fields.service';
import { PaymentService } from '../../services/payment.service';
import { PaymentFluxService } from '../../services/utils/payment-flux.service';
import { CustomerService } from '../../services/utils/user/customer-handler.service';
import { customerReferenceDhlData } from '../../utils/companies/dhl';
import { customerReferenceEmotransData } from '../../utils/companies/emotrans';
import { customerReferenceGeodisData } from '../../utils/companies/geodis';
import { customerReferenceKNData } from '../../utils/companies/kn';
import { customerReferenceRhenusData } from '../../utils/companies/rhenus';
import { customerReferenceSchenkerData } from '../../utils/companies/schenker';
import { companyName, customerReference } from '../../utils/constants';
import { customerReferenceExpeditorsData } from '../../utils/facilities/expeditors';
import { DetailsFieldComponent } from '../details-section/details-field/details-field.component';
import { DetailsHeaderComponent } from '../details-section/details-header/details-header.component';
import { FilesContainerComponent } from '../files/files-container';
export interface Detail {
    label: string;
    value: string;
    show?: boolean;
}
@Component({
    selector: 'app-customer-reference',
    standalone: true,
    imports: [CommonModule, MatButtonModule, FilesContainerComponent, DetailsHeaderComponent, DetailsFieldComponent],
    templateUrl: './customer-reference.component.html',
})
export class CustomerReferenceComponent implements OnInit, AfterViewInit {
    hasExternalLookup: boolean = false;
    customer: any;
    companyName: string;
    customerReference: string;
    notificationEmail: string;
    geodisUser: boolean;
    isExpeditors: boolean;
    id: string;
    dynamicValuesReference: any;
    isStartingLevel: boolean;
    pathFiles: string[];
    titleFile: string;
    readonly geodisDomain: boolean;
    private _dynamicFields: any;
    private readonly currentPayment: Payment | null;

    @Input() paymentDetail: any;
    @Input() values: Detail[];

    constructor(
        private _customerService: CustomerService,
        private _router: Router,
        private _paymentFluxService: PaymentFluxService,
        private _paymentService: PaymentService,
        private dynamicFieldsService: DynamicFieldsService
    ) {
        this.titleFile = '';
        this.pathFiles = [];
        this.geodisDomain = this._customerService.isCustomerEmailInDomain(companyName.geodis.toLowerCase());
        this.currentPayment = this._paymentFluxService.getCurrentPayment();
        this.id =
            this.paymentDetail && this.paymentDetail.id
                ? this.paymentDetail.id
                : this.currentPayment !== undefined
                  ? this.currentPayment?.id
                  : '';
        this.customer = this._customerService.getCustomer();
        this.dynamicValuesReference = {};
        this._dynamicFields = this._paymentFluxService.getDynamicFields();
        this.companyName = this.customer.approvalLevels ? this.customer.approvalLevels.company.name : null;
        this.customerReference = this._setCustomerReferenceLabel();
        this.notificationEmail = this.geodisUser
            ? customerReferenceGeodisData.titleNotificationEmail
            : 'Payment Notification Email';
        this.isStartingLevel = this._customerService.getStartingLevel();
    }

    ngOnInit(): void {
        this.hasExternalLookup =
            this.paymentDetail && this.paymentDetail.externalLookup
                ? this.paymentDetail.externalLookup
                : this.currentPayment?.facility
                  ? this.currentPayment.facility.externalLookup
                  : false;
        this.getFormFields();
        this.getIsReplacement();
    }

    ngAfterViewInit(): void {
        this.getLabels();
    }

    private _setCustomerReferenceLabel(): string {
        if (!this.isExpeditors) {
            if (this.companyName) {
                switch (this.companyName) {
                    case companyName.geodis:
                        return customerReferenceGeodisData.titleCustomerReference;
                    case companyName.kn:
                        return customerReferenceKNData.title;
                    case companyName.schenker:
                        return customerReferenceSchenkerData.title;
                    case companyName.dhl:
                        return customerReferenceDhlData.title;
                    case companyName.emotrans:
                        return customerReferenceEmotransData.title;
                    case companyName.rhenus:
                        return customerReferenceRhenusData.title;
                    default:
                        return customerReference.titleCustomerReference;
                }
            } else if (this.geodisDomain) {
                return customerReferenceGeodisData.titleCustomerReference;
            } else {
                return 'Reference';
            }
        } else {
            return customerReferenceExpeditorsData.titleCustomerReference;
        }
    }

    /**
     * @method editPaymentRequest()
     * @description First we review if the payment request has an ID, if we have and ID we call the service to generate the necessary data for the payment request process
     */

    editPaymentRequest(): void {
        this.paymentDetail.step = 2;
        this._paymentFluxService.setAndSaveCurrentPayment(this.paymentDetail);
        if (!this._paymentFluxService.getDynamicValues()) {
            this._paymentFluxService.setDynamicValues(this.paymentDetail.dynamicValues);
        }
        this._router.navigate(['/admin/facilityPayments/newPayment/flux/customerReference']);
    }

    /**
     * @method getLabels()
     * @description
     */

    getLabels(): void {
        const dynamicCompany: boolean = this._customerService.isDynamicCompany();
        if (dynamicCompany) {
            if (this.paymentDetail && this.paymentDetail.reference && this._dynamicFields && this._dynamicFields?.[2]) {
                Object.keys(this._dynamicFields[2]).forEach((key: any): void => {
                    if (this._dynamicFields[2][key].isReplacement === false) {
                        this.dynamicValuesReference[this._dynamicFields[2][key].label] =
                            this.paymentDetail.reference[this._dynamicFields[2][key].name];
                    }
                });
            }
            if (this._dynamicFields?.[2] && this.dynamicValuesReference) {
                const fileInput = this._dynamicFields[2]
                    .filter((item: any) => item.fieldType === 'file')
                    .map((filteredItem: any) => {
                        this.titleFile = filteredItem.label;
                        return filteredItem.label;
                    });
                if (this.dynamicValuesReference && fileInput && this.dynamicValuesReference[fileInput]) {
                    this.pathFiles = this.dynamicValuesReference[fileInput].split(',');
                    delete this.dynamicValuesReference[fileInput];
                }
            }
        }
    }

    /**
     * @method keys()
     * @description
     */

    keys(): Array<string> {
        return Object.keys(this.dynamicValuesReference);
    }

    /**
     * @method getFormFields()
     * @description
     */

    getFormFields(): void {
        this._dynamicFields = {};
        this.dynamicFieldsService.getFormFields$(2).subscribe((result): void => {
            result?.map((item) => {
                if (this.paymentDetail?.dynamicValues && item.id && !item.isReplacement) {
                    this.paymentDetail.reference[item.name!] =
                        this.paymentDetail.dynamicValues[item.id] !== ''
                            ? this.paymentDetail.dynamicValues[item.id] !== undefined
                                ? this.paymentDetail.dynamicValues[item.id]
                                : 'N/A'
                            : 'N/A';
                }
            });
            this._dynamicFields[2] = result;
            this.getLabels();
        });
    }

    /**
     * @method getIsReplacement()
     * @description Review if dynamic fields exits and if they do, we review if we have any isReplaced field
     */

    getIsReplacement(): void {
        this.dynamicFieldsService
            .getFormFields$(2)
            .pipe(take(1))
            .subscribe((result): void => {
                result?.forEach((field) => {
                    if (field.isReplacement === true) {
                        if (field.name === 'customerRef') {
                            this.customerReference = field.label!;
                            this.dynamicValuesReference[this.customerReference] !== undefined
                                ? delete this.dynamicValuesReference[this.customerReference]
                                : null;
                        }
                    }
                });
            });
    }
}
