import { Injectable } from '@angular/core';
import { DynamicField, DynamicSections } from '@cargos/sprintpay-models';
import { BehaviorSubject, Observable, catchError, map, of, switchMap, throwError } from 'rxjs';
import { companyName } from '../utils/constants';
import { HandlerdFeaturesService } from './customer.service';
import { DynamicFieldAPIService } from './requests/dynamic-field-api.service';
import { CustomerService } from './utils/user';
export interface DynamicFieldsForPayment extends DynamicSections {
    fields?: DynamicField[];
}

export interface ColumnsFormat {
    column?: string;
    columnName?: string;
}

export interface ColumnsFieldsReplaced {
    name: any;
    label: any;
}

@Injectable({ providedIn: 'root' })
export class DynamicFieldsService {
    private loading = new BehaviorSubject<boolean>(false);
    private fieldsForPayment = new BehaviorSubject<DynamicFieldsForPayment[]>([]);
    private dynamicFieldsByFacility = new BehaviorSubject<DynamicFieldsForPayment[]>([]);
    private dynamicFieldsForPayment = new BehaviorSubject<DynamicField[]>([]);

    constructor(
        private dynamicFieldAPIService: DynamicFieldAPIService,
        private handlerdFeaturesService: HandlerdFeaturesService,
        private customerService: CustomerService
    ) {}

    get instant_dynamic_fields_for_payment(): DynamicField[] {
        return this.dynamicFieldsForPayment.value;
    }

    getAllDynamicFields(): DynamicField[] {
        return this.dynamicFieldsForPayment.value;
    }

    getAllDynamicFields$(): Observable<DynamicField[]> {
        return this.dynamicFieldsForPayment.asObservable();
    }

    getDynamicColumns(): Observable<ColumnsFormat[]> {
        return this.getDynamicFieldsForPayment$().pipe(
            map((fieldsForPayment: DynamicFieldsForPayment[]) => {
                if (fieldsForPayment.length) {
                    return fieldsForPayment.flatMap((fieldForPayment: DynamicFieldsForPayment) => {
                        return (
                            fieldForPayment.fields
                                ?.filter((item): boolean => item.isHidden === false)
                                .map((field): ColumnsFormat => {
                                    return { columnName: field.label, column: field.name };
                                }) || []
                        );
                    });
                }
                return [];
            })
        );
    }

    getDynamicFields$(): Observable<DynamicField[]> {
        return this.getDynamicFieldsForPayment$().pipe(
            map((fieldsForPayment: DynamicFieldsForPayment[]) => {
                return fieldsForPayment.flatMap((fieldForPayment: DynamicFieldsForPayment) => {
                    return fieldForPayment.fields || [];
                });
            })
        );
    }

    getDynamicFieldsCurrentValue(): Observable<DynamicField[]> {
        return this.getDynamicFieldsForPayment$().pipe(
            map((fieldsForPayment: DynamicFieldsForPayment[]) => {
                return fieldsForPayment.flatMap((fieldForPayment: DynamicFieldsForPayment) => {
                    return fieldForPayment.fields || [];
                });
            })
        );
    }

    getFieldsReplaced(): Observable<ColumnsFieldsReplaced[]> {
        return this.getDynamicFieldsForPayment$().pipe(
            map((fieldsForPayment: DynamicFieldsForPayment[]) => {
                return fieldsForPayment.flatMap((fieldsForPayment: DynamicFieldsForPayment) => {
                    return (
                        fieldsForPayment.fields
                            ?.filter((item): boolean => !!item.isReplacement)
                            .map((field): ColumnsFieldsReplaced => {
                                return { name: field.name, label: field.label };
                            }) || []
                    );
                });
            })
        );
    }

    getFormFields$(section: number): Observable<DynamicField[]> {
        return this.getDynamicFieldsForPayment$().pipe(
            map((dynamicFieldsForPayment) => {
                if (section >= 0 && section < dynamicFieldsForPayment.length) {
                    return dynamicFieldsForPayment[section].fields || [];
                }
                return [];
            }),
            map((formField: DynamicField[]) => {
                return formField.filter((form: DynamicField) => form.isDynamic);
            })
        );
    }

    getDynamicFieldBySection(section: number): DynamicField[] {
        const dynamic_fields = this.instant_current_dynamic_fields_by_facility;
        if (section >= 0 && section < dynamic_fields.length) {
            return dynamic_fields[section]?.fields || [];
        }
        return [];
    }

    getHeaderdinamicAndReplaceable(keyHeaders: string[], staticHeaders: string[], dinamicFields: any): string[] {
        dinamicFields.map((field: { name: string; label: string }) => {
            const index = keyHeaders.findIndex((value) => value === field.name);
            if (index > 0) {
                staticHeaders[index] = field.label;
            }
        });
        return staticHeaders;
    }

    /**
     * @method getLabelsdinamicAndReplaceable()
     * @description Review if dynamic fields exits and if they do, we review if we have any isReplaced field
     */

    getLabelsdinamicAndReplaceable(staticNames: string[], dinamicFields: any) {
        let dynamicLabels: any = {};
        Object.values(dinamicFields).map((item: any) => {
            if (staticNames.indexOf(item.name) !== -1) {
                dynamicLabels[`${item.name}`] = item.label;
            }
        });
        return dynamicLabels;
    }

    /**
     * @method getFileFieldsDynamic()
     * @param fields
     * @description Review if dynamic fields has file input
     */
    getFileFieldsDynamic(): {
        id: number;
        name: string;
    }[] {
        return this.fieldsForPayment.value.flatMap((fieldsForPayment) => {
            return (
                fieldsForPayment?.fields
                    ?.filter(
                        (field: DynamicField) => field.fieldType === 'file' && !field.isReplacement && !field.isHidden
                    )
                    .map((field: DynamicField) => ({
                        id: field.id!, // Asegúrate de que `id` y `name` siempre estén definidos, de lo contrario usa una verificación más adecuada
                        name: field.name!,
                    })) || []
            );
        });
    }

    /**
     * @method verifyIfDynamicFieldisFile()
     * @param id
     * @param dynamicFileFields
     * @description Review if dynamic fields is a dynamic file field
     */
    verifyIfDynamicFieldisFile(
        id: number,
        dynamicFileFields: { id: number; name: string }[]
    ): { id: number; name: string }[] {
        return dynamicFileFields.filter((field: { id: number; name: string }) => id === field.id);
    }

    getDynamicFieldsByCompany(section?: number): Observable<DynamicField[]> {
        return this.dynamicFieldAPIService.getDynamicFieldsBySection(section).pipe(
            catchError(() => {
                return of([]);
            })
        );
    }

    getDynamicSectionsByCompany(companyId?: number): Observable<DynamicSections[]> {
        return this.dynamicFieldAPIService.getDynamicSectionsByCompany(companyId).pipe(
            catchError(() => {
                return of([]);
            })
        );
    }

    get instant_current_dynamic_fields_for_payment(): DynamicFieldsForPayment[] {
        return this.fieldsForPayment.value;
    }

    setDynamicFieldsForPayment(dynamicFieldsForPayment: DynamicFieldsForPayment[]): void {
        this.fieldsForPayment.next(dynamicFieldsForPayment);
    }

    getDynamicFieldsForPayment$(): Observable<DynamicFieldsForPayment[]> {
        return this.fieldsForPayment.asObservable();
    }

    get instant_current_dynamic_fields_by_facility(): DynamicFieldsForPayment[] {
        return this.dynamicFieldsByFacility.value;
    }

    setDynamicFieldsByFacility(dynamicFieldsForPayment: DynamicFieldsForPayment[]): void {
        this.dynamicFieldsByFacility.next(dynamicFieldsForPayment);
    }

    getDynamicFieldsByFacility$(): Observable<DynamicFieldsForPayment[]> {
        return this.dynamicFieldsByFacility.asObservable();
    }

    setLoadingDynamicFields(loading: boolean): void {
        this.loading.next(loading);
    }

    getLoadingDynamicFields(): Observable<boolean> {
        return this.loading.asObservable();
    }

    getDynamicSections(companyId?: number | null): Observable<DynamicSections[]> {
        if (!!companyId) {
            return this.getDynamicSectionsByCompany(companyId).pipe(
                switchMap((sections) => {
                    if (sections.length) {
                        return of(sections.map((section) => ({ ...section, title: section.label })));
                    }
                    return this.getStaticSections();
                })
            );
        }
        return this.getStaticSections();
    }

    getStaticSections(): Observable<DynamicSections[]> {
        return this.handlerdFeaturesService.getCustomerSections();
    }

    getStaticFields(): Observable<DynamicField[]> {
        return this.handlerdFeaturesService.getCustomerFields();
    }

    filterDuplicatedFields(staticFields: DynamicField[] = [], dynamicFields: DynamicField[] = []): DynamicField[] {
        const newDynamicFields: DynamicField[] = [...dynamicFields];

        staticFields?.forEach((staticField) => {
            if (!dynamicFields?.find((field) => field.name === staticField.name && field.isReplacement)) {
                newDynamicFields.push(staticField);
            }
        });
        return newDynamicFields;
    }

    updateFields(staticFields: DynamicField[] = []): DynamicField[] {
        staticFields = staticFields.map((field) => {
            const customer = this.handlerdFeaturesService.getCustomer();
            const uniqueRequestor = this.handlerdFeaturesService.isUniqueRequestor();
            const company = this.handlerdFeaturesService.getCompanyName();
            const geodisDomain = this.customerService.isCustomerEmailInDomain('geodis');
            const isUserCevaDomain = this.customerService.isCevaDomain();
            const customerProfile = this.customerService.getCustomerProfile();

            if (field.name === 'amount') {
                if (customer?.invoiceCheckLimit && !uniqueRequestor) {
                    field.validations = field.validations?.filter((validation) => validation.name !== 'max');
                    field.validations?.push({
                        id: 3,
                        name: 'max',
                        value: customer?.invoiceCheckLimit.toString(),
                        message: `Your requested amount is higher than your current allowed transaction limit. Please contact collections@cargosprint.com`,
                        title: null,
                        type: 'VALIDATION',
                    });
                }
                if (customer && customerProfile?.customerProfileRestriction?.creditCardLimit) {
                    field.validations = field.validations?.filter((validation) => validation.name !== 'max');
                    field.validations?.push({
                        id: 3,
                        name: 'max',
                        value: customerProfile?.customerProfileRestriction?.creditCardLimit,
                        message: customerProfile?.customerProfileRestriction?.description,
                        title: null,
                        type: 'VALIDATION',
                    });
                }
            }

            if (field.name === 'customerRef') {
                if (!this.handlerdFeaturesService.isStartingLevel()) {
                    if (this.handlerdFeaturesService.getCustomer()?.hasGlCodes) {
                        if (this.handlerdFeaturesService.getCustomer()?.expeditors) {
                            field.placeholder = 'Maximum 10 alpha-numeric characters allowed';
                        }
                        if (geodisDomain) {
                            field.tooltipMessage = 'This field is where you must enter your GEODIS Job/Shipment';
                        } else if (isUserCevaDomain) {
                            field.label = 'CEVA Dossier Number';
                            field.placeholder = 'Enter CEVA Dossier Number';
                            field.tooltipMessage = 'Must contain 13 numeric characters';
                        } else {
                            field.label = 'File #';
                            field.placeholder =
                                'Your own personal reference # so you can remember this payment. For example your file #.';
                            field.tooltipMessage =
                                'Your own personal reference # so you can remember this payment. For example your file #.';
                        }
                    }
                }
            }

            if (field.name === 'email') {
                field.label = 'Payment notification email';
                if (this.handlerdFeaturesService.isStartingLevel()) {
                    if (this.handlerdFeaturesService.getCustomer()?.hasGlCodes) {
                        if (this.handlerdFeaturesService.getCustomer()?.expeditors) {
                            field.placeholder = 'Maximum 10 alpha-numeric characters allowed';
                        }
                        const geodisDomain = this.customerService.isCustomerEmailInDomain('geodis');
                        const isUserCevaDomain = this.customerService.isCevaDomain();
                        if (geodisDomain) {
                            field.tooltipMessage = 'This field is where you must enter your GEODIS Job/Shipment';
                        } else if (isUserCevaDomain) {
                            field.label = 'CEVA Dossier Number';
                            field.placeholder = 'Enter CEVA Dossier Number';
                            field.tooltipMessage = 'Must contain 13 numeric characters';
                        } else {
                            field.label = 'File #';
                            field.placeholder =
                                'Your own personal reference # so you can remember this payment. For example your file #.';
                            field.tooltipMessage =
                                'Your own personal reference # so you can remember this payment. For example your file #.';
                        }
                    }
                }
                if (company !== companyName.geodis && !customer?.isGuest) {
                    field.value = customer?.notificationEmail || customer?.email;
                }
                if ([companyName.kn, companyName.schenker].includes(company)) {
                    field.isDisabled = true;
                }
            }
            return field;
        });
        return staticFields;
    }

    removeNotAllowedFields(staticFields: DynamicField[] = []): DynamicField[] {
        if (this.handlerdFeaturesService.isStartingLevel()) {
            staticFields = staticFields.filter((staticField) => staticField.name !== 'paymentType');
        }
        // if (!this.handlerdFeaturesService.hasGlCodes()) {
        //     staticFields = staticFields.filter((staticField) => staticField.name !== 'glCode');
        // }
        const firstLocation = this.handlerdFeaturesService.getFirstLocation();
        if (!!firstLocation?.id) {
            staticFields = staticFields.filter(
                (staticField) => staticField.name !== 'locationId' && staticField.name !== 'locationddId'
            );
            this.handlerdFeaturesService.setLocation(firstLocation);
        }

        return staticFields;
    }

    needsAutoFill(staticFields: DynamicField[] = []): DynamicField[] {
        staticFields = staticFields.map((field) => {
            if (field.name === 'paymentAmount' && field.section === 0) {
                field.options = this.handlerdFeaturesService.getAmount();
            }
            return field;
        });
        return staticFields;
    }

    getDynamicFields(): Observable<DynamicField[]> {
        return this.getDynamicFieldsByCompany().pipe(
            map((fields) => {
                return fields.map((field) => ({ ...field, isDynamic: true }));
            }),
            map((fields) => {
                return this.setDisplayValue(fields);
            }),
            switchMap((dynamicFields) => {
                return this.getStaticFields().pipe(
                    map((fields) => {
                        return fields.map((field) => ({ ...field, isDynamic: false }));
                    }),
                    map((staticFields) => this.filterDuplicatedFields(staticFields, dynamicFields)),
                    map((fields) => this.needsAutoFill(fields)),
                    map((fields) => this.removeNotAllowedFields(fields)),
                    map((fields) => this.updateFields(fields))
                );
            })
        );
    }

    setDisplayValue(fields: DynamicField[]): DynamicField[] {
        return fields.map((field) => {
            if (field.fieldType === 'dropdown' || field.fieldType === 'radioButton' || field.fieldType === 'checkbox') {
                const updatedOptions = field.options?.map((option) => {
                    if (option.displayValue === undefined || option.displayValue === null) {
                        return { ...option, displayValue: option.value };
                    }
                    return option;
                });
                return { ...field, options: updatedOptions };
            }
            return field;
        });
    }

    getSectionByNumber(sections: DynamicFieldsForPayment[], sectionNumber?: number): DynamicFieldsForPayment | null {
        return (
            sections.find((section) => {
                return section.orderNumber === sectionNumber;
            }) || null
        );
    }

    orderFieldByIsDynamic(fields: DynamicField[]): DynamicField[] {
        return fields.sort((a, b) => {
            if (a.name === 'customerRef') return -1;
            if (b.name === 'customerRef') return 1;
            if (a.name === 'nameFiles') return 1;
            if (b.name === 'nameFiles') return -1;
            if (a.isDynamic === b.isDynamic) {
                return 0;
            }
            return a.isDynamic ? -1 : 1;
        });
    }

    addDynamicFieldsToSection(
        dynamicSections: DynamicSections[] = [],
        dynamicFields: DynamicField[] = []
    ): DynamicFieldsForPayment[] {
        const dynamicFieldsForPayment: DynamicFieldsForPayment[] = [];

        if (dynamicSections.length) {
            dynamicSections.forEach((section, index) => {
                dynamicFieldsForPayment[index] = section;

                if (section.id) {
                    const fieldsPerSection = dynamicFields.filter((field) => field.section === section.orderNumber);
                    dynamicFieldsForPayment[index].fields = this.orderFieldByIsDynamic(fieldsPerSection);
                }
            });
        } else if (dynamicFields.length) {
            dynamicFields.forEach((field) => {
                if (!this.getSectionByNumber(dynamicFieldsForPayment, field.section)) {
                    dynamicFieldsForPayment.push({
                        orderNumber: field.section,
                    });
                }

                const section = this.getSectionByNumber(dynamicFieldsForPayment, field.section);

                if (section) {
                    const fieldsPerSection = dynamicFields.filter((field) => field.section === section.orderNumber);
                    section.fields = this.orderFieldByIsDynamic(fieldsPerSection);
                }
            });
        }
        return dynamicFieldsForPayment;
    }

    removeEmptySections(dynamicFields: DynamicFieldsForPayment[]) {
        return dynamicFields.filter((section) => !!section.fields?.length);
    }

    orderSectionByOrderNumber(sections: DynamicFieldsForPayment[] = []): DynamicFieldsForPayment[] {
        try {
            return sections.sort((a, b) => {
                return (a?.['orderNumber'] || 0) - (b?.['orderNumber'] || 0);
            });
        } catch (error) {
            return sections;
        }
    }

    getDynamicFieldsPerSection(companyId: number | null): Observable<DynamicFieldsForPayment[]> {
        this.setLoadingDynamicFields(true);
        return this.getDynamicSections(companyId).pipe(
            switchMap((dynamicSections: DynamicSections[]) => {
                return this.getDynamicFields().pipe(
                    map((dynamicFields) => {
                        this.dynamicFieldsForPayment.next(dynamicFields);
                        return this.addDynamicFieldsToSection(dynamicSections, dynamicFields);
                    })
                );
            }),
            map((dynamicFields) => {
                const dynamicFieldsOrdered = this.orderSectionByOrderNumber(dynamicFields);
                this.setDynamicFieldsForPayment(dynamicFieldsOrdered);

                this.setLoadingDynamicFields(false);
                return dynamicFieldsOrdered;
            }),
            catchError((error) => {
                this.setLoadingDynamicFields(false);

                this.setDynamicFieldsForPayment([]);
                return throwError(() => error);
            })
        );
    }

    getFieldByDependencyFieldId(dependentFieldId: number): DynamicField | null {
        const dynamicFields = this.instant_current_dynamic_fields_for_payment || [];
        return this.getAllDynamicFields().find((field) => field.dependentFieldId === dependentFieldId) || null;
    }

    getOrderNumberByField(controls: string[]): number {
        const sections = this.instant_current_dynamic_fields_by_facility;
        let orderNumber: number = -1;
        for (const item of sections) {
            const fieldNames = item.fields?.map((field: DynamicField) => field.name || '') || [];
            if (controls.every((field) => fieldNames.includes(field))) {
                orderNumber = item.orderNumber!;
            }
        }
        return orderNumber;
    }
}
