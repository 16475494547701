<div id="common_facility_data" class="flex-column">
    <div class="title px-2"><span class="material-symbols-outlined"> warehouse </span>Vendor:</div>
    <div>
        <div class="facility-name d-flex">
            <img src="assets/images/eModal-logo.svg" alt="facility" width="88px" height="20px" *ngIf="isEnabledImg" />
            <h6 *ngIf="!isEnabledImg">{{facility?.name ?? ''}}</h6>
        </div>

        <div class="facility-address mb-0">{{formatAddress(facility)}}</div>
        <span class="facilities-data--type d-block d-lg-none">
            <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'electronic'">
                <mat-icon class="me-2 align-middle">bolt</mat-icon>
                Electronic < 1 hour
            </ng-container>
            <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'messenger'">
                <mat-icon class="me-2 align-middle">bolt</mat-icon>
                Messenger < 3 hour
            </ng-container>
            <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'fedex'">
                <mat-icon class="me-2 align-middle">bolt</mat-icon>
                FedEx = Next business day by 10:30 AM
            </ng-container>
        </span>
    </div>
    <span class="facilities-data--type d-none d-lg-flex">
        <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'electronic'">
            <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
            Electronic < 1 hour
        </ng-container>
        <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'messenger'">
            <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
            Messenger < 3 hour
        </ng-container>
        <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'fedex'">
            <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
            FedEx = Next business day by 10:30 AM
        </ng-container>
    </span>
</div>
