import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { Payment } from 'src/app/models/payments/payment.model';
import { CustomerService } from 'src/app/services/utils/user/customer-handler.service';
import { PaymentFluxService } from '../../../services/utils/payment-flux.service';

@Component({
    selector: 'app-geodis-customer-reference',
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    templateUrl: './geodis-customer-reference.component.html',
})
export class GeodisCustomerReferenceComponent implements OnInit {
    public hasExternalLookup: boolean = false;
    public id: string;
    public isStartingLevel: boolean;
    private currentPayment: Payment | null;

    @Input() paymentDetail: any;

    constructor(
        private router: Router,
        private paymentFluxService: PaymentFluxService,
        private customerService: CustomerService
    ) {
        this.id = '';
        this.currentPayment = this.paymentFluxService.getCurrentPayment();
        this.isStartingLevel = this.customerService.getStartingLevel();
    }

    ngOnInit(): void {
        this.id =
            this.paymentDetail && this.paymentDetail.id
                ? this.paymentDetail.id
                : this.currentPayment !== undefined
                  ? this.currentPayment?.id
                  : '';
        this.hasExternalLookup =
            this.paymentDetail && this.paymentDetail.externalLookup
                ? this.paymentDetail.externalLookup
                : this.currentPayment && this.currentPayment.facility
                  ? this.currentPayment.facility.externalLookup
                  : false;
    }

    /**
     * @method editPaymentRequest()
     * @description First we review if the payment request has an ID, if we have and ID we call the service to generate the necessary data for the payment request process
     */

    editPaymentRequest() {
        const redirect: string = this.paymentDetail.redirectRoute ? this.paymentDetail.redirectRoute : this.router.url;
        this.paymentFluxService.setAndSaveCurrentPayment(this.paymentDetail);
        this.router.navigate(['/admin/facilityPayments/newPayment/flux/geodisReference']);
    }
}
