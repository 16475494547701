import { Facility } from '@cargos/sprintpay-models';
import * as moment from 'moment';
import { FormatPaymentReferencePipe } from 'src/app/pipes/format-payment-reference.pipe';
import { SecurityService } from '../../services/utils/security.service';
import { paymentMethod, permissionsName, profileComponents } from '../constants';
import { sortPaymentTypes } from '../ordered-data';

export const customerReferenceKN: RegExp[] = [
    /^\d{10}(-\d{4})$/,
    /^\d{2}(-\d{4})(-\d{3}(\.\d{3}))$/,
    /^[a-zA-z]{2}\d{8}(-\d{4})$/,
];

export const customerReferenceKNData = {
    title: 'ACON FILE Number',
    placeholder: 'Enter ACON FILE Number',
};

export class KN {
    static userPermissionsSubmit: Array<string> = [];
    static userPermissionsCart: Array<string> = [];
    static readonly paymentReferencePipe = new FormatPaymentReferencePipe();

    constructor(private _securityService: SecurityService) {
        const userType: string = this._securityService.getUserType();

        if (this._securityService.verifyComponentsSecurity(profileComponents.sendRequestofTableToApprover)) {
            KN.userPermissionsSubmit.push(userType);
        }
        if (this._securityService.verifyComponentsSecurity(profileComponents.addRequestofTableToCart)) {
            KN.userPermissionsCart.push(userType);
        }
    }

    static readonly userTypeKn = {
        requestor: permissionsName.knRequestor,
        requestorApprover: permissionsName.knRequestorApprover,
        approver: permissionsName.knApprover,
        financie: permissionsName.knFinancie,
    };
    static readonly currentDate = moment().format('YYYY-MM-DD 00:00:00');

    static readonly paymentTypes = Object.freeze(
        sortPaymentTypes([
            'Breakdown fee',
            'Charter/ULD Bypass Fees',
            'Import Service Fee (ISC)',
            'ISC + Storage',
            'Pallets/Wood skids',
            'Storage/Demurrage',
            'Other***',
        ])
    );

    /*
     * OPEN INVOICES
     */

    static readonly kn_ColumnsOpenInvoices: string[] = [
        'select',
        'invoiceNumber',
        'createdDate',
        'customerReference',
        'requesterEmail',
        'facilityReference',
        'location',
        'amount',
        'shortPaymentAmount',
        'balanceDue',
        'dueDate',
    ];
    static readonly kn_ColumnsNameOpenInvoices: string[] = [
        'select',
        'Invoice number',
        'Created date',
        'Customer reference',
        'Requester email',
        'Facility reference',
        'Location',
        'Amount',
        'Short Payment Amount',
        'Balance due',
        'Due date',
    ];
    static readonly kn_getCompletedPaymentsOpenInvoices = (item: any) => ({
        invoiceNumber: item.name ? item.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : '',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        requesterEmail: item.paymentNotificationEmail
            ? item.paymentNotificationEmail
            : item.customer && item.customer.email
              ? item.customer.email
              : 'N/A',
        facilityReference: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        shortPaymentAmount: item.paymentShort ? item.paymentShort : 'N/A',
        balanceDue: item.balanceDue ? '$' + Number.parseFloat(item.balanceDue).toFixed(2) : 'N/A',
        dueDate: item.dueDate ? moment.utc(item.dueDate).format('MMM DD, YYYY') : '',
        overDue:
            moment.utc(item.dueDate).startOf('day').diff(KN.currentDate, 'days') >= 0 &&
            moment.utc(item.dueDate).startOf('day').diff(KN.currentDate, 'days') < 7
                ? 'almostDue'
                : moment.utc(item.dueDate).startOf('day').diff(KN.currentDate, 'days') < 0
                  ? 'overdue'
                  : 'onTime',
        id: item.id,
        location: item.location ? item.location.name : 'N/A',
        hasDownloadableContent: true,
        disabledCheckbox: item?.achDebitId,
    });
    static readonly kn_ColumnsSortableOpenInvoices: string[] = ['createdDate', 'amount', 'dueDate'];

    /*
     * PAID INVOICES
     */

    static readonly kn_ColumnsPaidInvoices: string[] = [
        'select',
        'invoiceNumber',
        'createdDate',
        'customerReference',
        'facilityReference',
        'location',
        'amount',
        'paymentDate',
        'paymentMethod',
    ];
    static readonly kn_ColumnsNamePaidInvoices: string[] = [
        'select',
        'Invoice number',
        'Created date',
        'ACON FILE Number',
        'Facility reference',
        'Location',
        'Amount',
        'Payment date',
        'Payment method',
    ];
    static readonly kn_getCompletedPaymentsPaidInvoices = (item: any) => ({
        invoiceNumber: item.name ? item.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        facilityReference: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        paymentDate: item.paymentDate ? moment(item.paymentDate).format('MMM DD, YYYY') : '',
        paymentMethod: item.paymentMethod ? (paymentMethod[item.paymentMethod.name] ?? item.paymentMethod.name) : 'N/A',
        confirmationId: item.confirmationId,
        id: item.id,
        location: item.location && item.location.name ? item.location.name : 'N/A',
        hasDownloadableContent: true,
    });
    static readonly kn_ColumnsSortablePaidInvoices: string[] = [
        'createdDate',
        'paymentDate',
        'amount',
        'paymentMethod',
    ];

    /*
     * REJECTED PAYMENTS
     */

    static readonly kn_ColumnsRejectedPayment: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'location',
        'customerReference',
        'amount',
        'approvalLevel',
    ].slice(0, 9);
    static readonly kn_ColumnsNameRejectedPayment: string[] = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Location',
        'ACON File Number',
        'Amount',
        'Status',
    ].slice(0, 9);
    static readonly kn_getCompletedPaymentsRejectedPayment = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow.location.name : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
    });
    static readonly kn_ColumnsSortableRejectedPayment: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'location',
        'customerReference',
        'amount',
    ];

    /*
     * VOIDED PAYMENTS
     */

    static readonly kn_ColumnsVoidedPayment: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'location',
        'customerReference',
        'amount',
        'approvalLevel',
    ].slice(0, 9);
    static readonly kn_ColumnsNameVoidedPayment: string[] = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Location',
        'ACON File Number',
        'Amount',
        'Status',
    ].slice(0, 9);
    static readonly kn_getCompletedPaymentsVoidedPayment = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow.location.name : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        approvalLevel:
            item.approvalFlowLevel.approvalLevel.name +
            ' of ' +
            item.approvalFlowLevel.approvalFlow.approvalFlowLevels.length +
            ' levels',
        attachmentFound: item.attachments ? ' Yes ' : 'N/A',
    });
    static readonly kn_ColumnsSortableVoidedPayment: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'location',
        'customerReference',
        'amount',
    ];

    /*
     * HISTORY
     */

    static readonly kn_ColumnsHistory: string[] = [
        'select',
        'linkId',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'location',
        'customerReference',
        'amount',
        'facilityPaymentDeliveryNotificationShort',
    ];
    static readonly kn_ColumnsNameHistory: string[] = [
        'select',
        'Payment reference #',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Location',
        'ACON File Number',
        'Amount',
        'Delivery type',
    ];
    static readonly kn_getCompletedPaymentsHistory: any = (item: any) => ({
        id: item.name ? item.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : '',
        facilityName: item.facility ? item.facility.name : 'N/A',
        link: `../paymentDetail/${item.vendorInvoiceNumber ? item.vendorInvoiceNumber : item.id}`,
        awb: item.awb ? item.awb : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        location: item.location ?? 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        paymentConfirmation: item.isPaymentConfirmationAvailable ? item.isPaymentConfirmationAvailable : '',
        externalPaymentConfirmation: item.isExternalPaymentConfirmationAvailable
            ? item.isExternalPaymentConfirmationAvailable
            : '',
        attachments: item.attachments ? item.attachments : null,
        hasDownloadableContent: item.hasDownloadableContent ?? true,
        facilityPaymentDeliveryNotificationShort:
            item.facility && item.facility.facilityPaymentDeliveryNotificationShort
                ? item.facility.facilityPaymentDeliveryNotificationShort
                : 'N/A',
        linkId: KN.paymentReferencePipe.transform(item.vendorInvoiceNumber, item.name),
    });
    static readonly kn_ColumnsSortableHistory: string[] = [
        'createdDate',
        'awb',
        'hawb',
        'location',
        'customerReference',
        'amount',
    ];

    /*
     * PENDING APPROVAL
     */

    static readonly kn_ColumnsPendingApproval: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'location',
        'customerReference',
        'amount',
        'approvalLevel',
    ];
    static readonly kn_ColumnsNamePendingApproval: string[] = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Location',
        'ACON File Number',
        'Amount',
        'Status',
    ];
    static readonly kn_getCompletedPaymentsPendingApproval: any = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.location?.name : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        approvalLevels: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.approvalFlowLevels : '',
        approvalLevel: item.approvalFlowLevel
            ? item.approvalFlowLevel.approvalLevel?.name +
              ' of ' +
              item.approvalFlowLevel.approvalFlow?.approvalFlowLevels?.length +
              ' levels'
            : 'Added to cart by - ' + item.customer?.name,
        attachmentFound: item.attachments ? ' Yes ' : 'N/A',
        approvalFlowLevel: item.approvalFlowLevel || '',
    });
    static readonly kn_ColumnsSortablePendingApproval: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'location',
        'customerReference',
        'amount',
        'approvalLevel',
    ];

    /*
     * PENDING APPROVAL HOME COMPONENT
     */

    static readonly kn_ColumnsPendingApprovalHomeComponent: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'location',
        'customerReference',
        'amount',
        'approvalLevel',
    ];
    static readonly kn_ColumnsNamePendingApprovalHomeComponent: string[] = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Location',
        'ACON File Number',
        'Amount',
        'Status',
    ];
    static readonly kn_getCompletedPaymentsPendingApprovalHomeComponent: any = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../facilityPayments/pendingApproval/paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.location?.name : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        approvalLevels: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.approvalFlowLevels : '',
        approvalLevel: item.approvalFlowLevel
            ? item.approvalFlowLevel.approvalLevel?.name +
              ' of ' +
              item.approvalFlowLevel.approvalFlow?.approvalFlowLevels?.length +
              ' levels'
            : 'Added to cart by - ' + item.customer?.name,
        attachmentFound: item.attachments ? ' Yes ' : 'N/A',
    });
    static readonly kn_ColumnsSortablePendingApprovalHomeComponent: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'location',
        'customerReference',
        'amount',
        'approvalLevel',
    ];

    /*
     * USERS
     */

    static readonly kn_ColumnsUsers: string[] = [
        'firstName',
        'lastName',
        'email',
        'homeLocation',
        'approverType',
        'approverLocations',
        'notificationEmail',
        'isMegafileEnabled',
    ];
    static readonly kn_ColumnsNameUsers: string[] = [
        'First name',
        'Last name',
        'Email address',
        'Home location',
        'Approver type',
        'Locations for approver visibility',
        'Email notifications',
        'Megafile',
    ];
    static readonly kn_getCompletedPaymentsUsers = (item: any) => ({
        firstName: item.firstName ? item.firstName : 'N/A',
        lastName: item.lastName ? item.lastName : 'N/A',
        email: item.email ? item.email : 'N/A',
        homeLocation: item.homeLocation ? item.homeLocation : 'N/A',
        approverType: item.approverType ? item.approverType : 'N/A',
        approverLocations: item.approverLocations ? item.approverLocations : 'N/A',
        notificationEmail: item.notificationEmail ? item.notificationEmail : 'N/A',
        isMegafileEnabled: item.isMegafileEnabled ? 'Yes' : 'N/A',
    });

    static readonly getPaymentDetailsPaymentSummary = (item: any, facility: Facility, id: string) => [
        {
            label: facility.externalLookup
                ? 'Container Number'
                : facility.awbFieldLabel === 'AWB'
                  ? 'RELATED BOL / AWB #'
                  : (facility.awbFieldLabel ?? 'N/A'),
            value: item.awb ? item.awb : 'N/A',
            show: true,
        },
        { label: 'Paid To', value: item.paidTo ? item.paidTo : 'N/A', show: true },
        {
            label: 'Amount',
            value: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
            show: true,
        },
        { label: 'HAWB/Container No.', value: item.hawb ? item.hawb : 'N/A', show: !facility.externalLookup },
    ];
    static readonly getCustomerReferencePaymentSummary = (item: any, customerReferenceLabel: string = '') => [
        {
            label: customerReferenceLabel ? customerReferenceLabel : customerReferenceKNData.title,
            value: item?.customerRef ? item.customerRef : 'N/A',
            show: true,
        },
        { label: 'Payment Notification Email', value: item?.email ? item.email : 'N/A', show: true },
        { label: 'Notes', value: item?.notes ? item.notes : 'N/A', show: true },
    ];
    static readonly kn_getCompanyCustomerReferencePaymentSummary: any = (item: any) => ({
        paymentAmount: item.paymentAmount || null,
        type: item.type ? item.type : null,
        locationName: item.locationName ? item.locationName : null,
    });

    static readonly getPaymentDetailsPaymentHistory = (item: any, facility: Facility, id: string) => [
        {
            label: facility.externalLookup ? 'File Number' : 'Second facility reference #',
            value: item.hawb ? item.hawb : 'N/A',
            show: !facility.externalLookup && !id,
        },
        { label: 'Paid To', value: item.paidTo ? item.paidTo : 'N/A', show: true },
        { label: 'Cargo facility', value: facility.name ? facility.name : 'N/A', show: true },
        { label: 'Amount', value: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A', show: true },
    ];
    static readonly getCustomerReferencePaymentHistory = (item: any, customerReferenceLabel: string = '') => [
        {
            label: customerReferenceLabel ? customerReferenceLabel : customerReferenceKNData.title,
            value: item.customerReference ? item.customerReference : 'N/A',
            show: true,
        },
        {
            label: 'Payment Notification Email',
            value: item.notificationEmail ? item.notificationEmail : 'N/A',
            show: true,
        },
        { label: 'Notes', value: item.notes ? item.notes : 'N/A', show: true },
    ];
    static readonly kn_getCompanyCustomerReferencePaymentHistory: any = (item: any) => ({
        paymentAmount: item.paymentAmount || null,
        type: item.type ? item.type : null,
        locationName: item.locationName ? item.locationName : null,
    });
    paymentDetails = [
        {
            label: 'AWB',
            id: 'awb',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Paid To',
            id: 'paidTo',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Amount',
            id: 'amount',
            value: 'N/A',
            type: 'currency',
        },
        {
            label: ' HAWB/Container No. ',
            id: 'hawb',
            value: 'N/A',
            type: 'string',
        },
    ];
    customerReference = [
        {
            label: customerReferenceKNData.title,
            id: 'customerRef',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Payment Notification Email',
            id: 'email',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Notes',
            id: 'notes',
            value: 'N/A',
            type: 'string',
        },
    ];
    knCustomerReference = [
        {
            label: 'Payment amount',
            id: 'paymentAmount',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Type',
            id: 'type',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Location',
            id: 'locationName',
            value: 'N/A',
            type: 'string',
        },
    ];
}
