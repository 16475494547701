import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Facility } from '@cargos/sprintpay-models';
import { FacilityService } from 'src/app/services/facility.service';

@Component({
    standalone: true,
    selector: 'app-facility-data',
    imports: [CommonModule, MatIconModule],
    templateUrl: './facility-data.component.html',
})
export class FacilityDataComponent implements OnInit {
    public nameFacility: string;
    public isEnabledImg: boolean = false;
    @Input() facility: Facility | null;
    @Input() showflightButton: boolean = false;
    @Output() clickflightButton: EventEmitter<any> = new EventEmitter<any>();

    constructor(private facilityService: FacilityService) {}

    ngOnInit(): void {
        this.validateShow();
    }

    validateShow(): void {
        this.isEnabledImg = !!this.facility?.paidTo?.toLowerCase().includes('emodal');
    }

    formatAddress(facility: Facility | null): string {
        return this.facilityService.formatAddress(facility);
    }
}
