<div class="row">
    <!-- START: DETAIL TITLE -->
    <div class="detail__sections mb-4">
        <div class="col-12">
            <div class="d-flex justify-content-between">
                <span class="my-3"><strong>K+N Customer reference</strong></span>
                <ng-container
                    *ngIf="(paymentDetail.approvalStatus === 'PENDING' && paymentDetail.uniqueRequestor && isStartingLevel && !paymentDetail.ownerCanPay && paymentDetail.sameCustomerAndRequestor) || (paymentDetail.approvalStatus === 'REJECTED' && isStartingLevel && paymentDetail.sameCustomerAndRequestor) || (isStartingLevel && paymentDetail.id === null ) || (isStartingLevel && paymentDetail.id !== null && !!paymentDetail.fromCart) && !hasExternalLookup"
                >
                    <button
                        mat-button
                        class="my-2 px-0 text-uppercase text-decoration-none"
                        (click)="editPaymentRequest()"
                    >
                        Edit
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- END: DETAIL TITLE -->
    <!-- START: DETAIL ELEMENTS -->
    <ng-container *ngIf="(!hasExternalLookup &&  !id) || id">
        <div class="col-12 col-md-4">
            <p class="card-subtitle me-2"><small>Payment Amount</small></p>
            <p>
                {{!!paymentDetail?.companyCustomerReference ? paymentDetail.companyCustomerReference.paymentAmount :
                paymentDetail?.paymentAmount || 'N/A' }}
            </p>
        </div>
    </ng-container>
    <ng-container *ngIf="(!hasExternalLookup &&  !id) || id">
        <div class="col-12 col-md-4">
            <p class="card-subtitle me-2"><small>Type</small></p>
            <p>
                {{!!paymentDetail?.companyCustomerReference ? paymentDetail.companyCustomerReference.type:
                paymentDetail?.type || 'N/A'}}
            </p>
        </div>
    </ng-container>
    <div class="col-12 col-md-4 col-lg-2">
        <p class="card-subtitle me-2"><small>Location</small></p>
        <p>
            {{!!paymentDetail?.location ? paymentDetail.location : paymentDetail?.companyCustomerReference?.locationName
            ? paymentDetail.companyCustomerReference.locationName : 'N/A'}}
        </p>
    </div>
    <!-- END: DETAIL ELEMENTS -->
</div>
