import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsTokensType, RedirectUrl } from '@cargos/sprintpay-models';
import { LoginAsCargoSprintUserResponse } from '@cargos/sprintpay-services/lib/apis/sprintpay/auth/models/auth-types';
import { catchError, map, Observable, of, switchMap, take, throwError } from 'rxjs';
import { LogoutResponse } from 'src/app/models/auth/logout.model';
import { LoginForm } from 'src/app/models/auth/signup.model';
import { UncompressTokenResult } from 'src/app/utils/auth-types';
import { environment } from 'src/environments/environment';
import { AuthAPIService } from '../requests/auth-api.service';
import { CartBillService } from './cart/cart-service';
import { TokenService } from './token.service';
import { CustomerService } from './user/customer-handler.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly authentication: string;

    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        private customerService: CustomerService,
        private authApiService: AuthAPIService,
        private cartBillService: CartBillService
    ) {
        this.authentication = environment.uris.method.authentication;
    }

    /**
     * @method reviewBeforeLogout()
     * @description Check if the user is Requester/Approval to remove the payments from the cart
     */
    reviewBeforeLogout(): Observable<LogoutResponse> {
        const canDeleteCartAutomatically = this.customerService.getCanDeleteCartAutomatically();
        if (canDeleteCartAutomatically && !this.isRefreshTokenExpired) {
            return this.cartBillService.deleteAndGetCart().pipe(
                take(1),
                switchMap(() => {
                    return this.logoff();
                })
            );
        }

        return this.logoff();
    }

    /**
     * @method reviewUserRequesterApproval()
     * @description Check if the user is Requester/Approval and if they have payments in the cart
     */

    reviewUserRequesterApproval(): Observable<boolean> {
        const company = this.customerService.getCompanyName();
        if (!!company) {
            return of(this.cartBillService.isThereItemsInCart());
        }
        return of(false);
    }

    /**
     * @method initConfigTokens()
     * @param (token: CredentialsTokensType)
     * @description
     */

    initConfigTokens(tokens: CredentialsTokensType) {
        this.tokenService.setAuthTokens(tokens);
    }

    /**
     * @method logoff()
     */

    private logoff(): Observable<LogoutResponse> {
        const refreshToken = this.tokenService.getCurrentAccessControl() ?? '';
        const isTokenExpired = this.tokenService.isTokenExpired(refreshToken);

        if (isTokenExpired) {
            const logoutResponse: LogoutResponse = {
                result: 'SUCCESS, TOKEN EXPIRED OR INVALID',
            };
            return of(logoutResponse);
        } else {
            const url: string = this.authentication + '/logoff';
            return this.http.get<LogoutResponse>(url, { params: { token: refreshToken } }).pipe(
                catchError(() => {
                    const logoutResponse: LogoutResponse = {
                        result: 'FAILURE, LOGOUT API ERROR',
                    };
                    return of(logoutResponse);
                })
            );
        }
    }

    /**
     * @method getIPAddress()
     * @description
     */
    getIPAddress(): Observable<string> {
        return this.http
            .get<{
                ip: string;
            }>('https://api.ipify.org/?format=json')
            .pipe(
                map((ipResponse) => ipResponse?.ip),
                catchError((error) => {
                    if (this.customerService.isYusenLogisticsUser()) {
                        return of('0.0.0.0');
                    }

                    return throwError(() => error);
                })
            );
    }

    /**
     * @method uncompressToken()
     * @param (compressToken: string)
     * @description
     */

    uncompressToken(compressToken: string, queryString?: string): Observable<UncompressTokenResult> {
        const compressUrl: string = `${this.authentication}/uncompress${queryString || ''}`;

        return this.http.post<UncompressTokenResult>(compressUrl, compressToken);
    }

    /**
     * @method compressToken()
     * @param (uncompressToken: string)
     * @description
     */

    compressToken(uncompressToken: string) {
        let uncompressUrl: string = `${this.authentication}/compress`;
        return this.http.post<any>(uncompressUrl, uncompressToken);
    }

    /**
     * @method current_user()
     * @description
     */

    get current_user(): any {
        const user = this.tokenService.getDecodedAccessToken()?.SPRINT_PAY_CUSTOMER;
        return user ? JSON.parse(user) : {};
    }

    /**
     * @method getSprintPayVersion()
     * @description
     */

    getSprintPayVersion(): string {
        return this.current_user.customer.sprintPayVersion;
    }

    /**
     * @method getSprintPayProfileType()
     * @description
     */

    getSprintPayProfileType(): string {
        return this.current_user.customer.profileType;
    }

    /**
     * @method signInAsGuest()
     * @description Sign in as guest
     */

    signInAsGuest(): Observable<CredentialsTokensType> {
        return this.http.get<CredentialsTokensType>(`${this.authentication}/guest?source=sprintpay`, {});
    }

    /**
     * @method validateActivationKey()
     * @description
     */

    validateActivationKey(activationKey: string): Observable<boolean> {
        return this.authApiService.validateActivationKeyRequest(activationKey);
    }

    /**
     * @method signIn()
     * @param (user: LoginForm)
     * @param (guestToken: number | null)
     * @description Sign in as authenticated customer
     */

    signIn(user: LoginForm): Observable<LoginAsCargoSprintUserResponse> {
        const url: string = `${this.authentication}/login?source=sprintpay&timestamp?${new Date().valueOf()}`;

        return this.http.post<LoginAsCargoSprintUserResponse>(url, user);
    }

    /**
     * Validates if the SSO email is valid and returns the redirect URL.
     * @param email - The SSO email to validate.
     * @returns An Observable of type RedirectUrl if the email is valid.
     */
    validateEmailSSO(email: string): Observable<RedirectUrl> {
        const url = `${this.authentication}/sso/redirecturl`;

        return this.http.post<RedirectUrl>(url, { email });
    }

    get isRefreshTokenExpired(): boolean {
        const refreshToken = this.tokenService.getCurrentAccessControl();
        return refreshToken ? this.tokenService.isTokenExpired(refreshToken) : true;
    }
}
