<div class="d-flex justify-content-center">
    <div class="element-diagram float-md-start float-lg-end d-block">
        <div
            id="refund-request-button"
            class="d-flex align-items-md-start align items-lg-end justify-content-md-start justify-content-lg-end"
            style="background-color: transparent"
            *ngIf="evalAction(rowRecord, refundAction)"
        >
            <mat-icon
                class="material-symbols-outlined"
                (click)="selectedAction(refundAction); $event.stopPropagation()"
                [ngStyle]="{cursor: !refundAction.refundEnabled || cleanDollarMask(rowRecord.amount) >= 0 ? 'pointer' : 'default', color: !refundAction.refundEnabled || cleanDollarMask(rowRecord.amount) >= 0 ? '' : 'rgba(3, 39, 71, 0.54)'}"
                [matTooltip]="'Refunds cannot be requested for credit notes'"
                [matTooltipDisabled]="!refundAction.refundEnabled || cleanDollarMask(rowRecord.amount) >= 0"
            >
                {{ refundAction.icon }}
            </mat-icon>
        </div>
    </div>
</div>
