import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, map } from 'rxjs';
import { FileAPIService } from 'src/app/services/requests/file-api.service';

@Injectable({
    providedIn: 'root',
})
export class FileHandlerService {
    constructor(
        private _fileService: FileAPIService,
        private _domSanitizer: DomSanitizer
    ) {}

    /**
     * @method downloadFile()
     * @param path
     * @param nameFile
     * @description Function that download the file
     */
    downloadFile(path: string, nameFile: string): Observable<Blob> {
        return this._fileService.downloadFileRequest(path).pipe(
            map((fileDownload: Blob) => {
                this.handleDownloadFile(fileDownload, nameFile);
                return fileDownload;
            })
        );
    }

    /**
     * @method uploadFile()
     * @param file
     * @description Function to upload a file and get a path
     */
    uploadFile(file: FormData): Observable<string> {
        return this._fileService.uploadFileRequest(file);
    }

    /**
     * @method removeFile()
     * @param path
     * @description Function to delete a file
     */
    removeFile(path: string): Observable<string> {
        return this._fileService.removeFileRequest(path);
    }

    /**
     * @method getNameOfFile()
     * @param path
     * @description with the paths, get only the name of file
     */
    getNameOfFile(path: string): string {
        const filePath = path?.split('/');
        return filePath ? filePath[filePath.length - 1] : '';
    }

    /**
     * @method handleDownloadFile()
     * @param (rawFile: Blob)
     * @param (fileName: string)
     * @description Prepare and download file
     */
    handleDownloadFile(rawFile: Blob, fileName: string): void {
        const link = document.createElement('a');
        const file = new Blob([rawFile], { type: rawFile?.type });
        const url = this._domSanitizer.sanitize(
            SecurityContext.URL,
            this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
        );

        if (url) {
            link.href = url;
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }
}
