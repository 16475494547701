import { commonEnv } from './environment.common';
import { environmentType } from './type';

export const environment: environmentType = {
    production: false,
    location: './assets/i18n/',
    locationImg: './assets/images/',
    environment: 'staging',
    cryptoKey: 'csspffe',
    projects: {
        sprintPay: 'https://beta.sprintpay.cargosprint.com',
        sprintPayv7: 'https://sprint-pay-staging.web.app',
        alliance: 'https://allianceground-test.web.app',
    },
    uris: {
        method: {
            cart: 'https://cart-staging.cargosprintgroup.com',
            payments: 'https://payments-staging.cargosprintgroup.com',
            invoices: 'https://invoices-staging.cargosprintgroup.com',
            facilities: 'https://facilities-staging.cargosprintgroup.com',
            authentication: 'https://auth-staging.cargosprintgroup.com',
            cards: 'https://card-payments-staging.cargosprintgroup.com/braintree',
            echecks: 'https://echecks-staging.cargosprintgroup.com',
            ops: 'https://ops-staging.cargosprintgroup.com',
            externalPayments: 'https://stg-api.cargosprintgroup.com/stg',
            customer: 'https://customer-staging.cargosprintgroup.com',
        },
    },
    paypalClientId: 'AbB2UwT0SaoLd7jFpQ0LCra6IXOhJfOPsSCkbK2SwOjJNAMt3hNOpQLRRao5Q8F4J7_ZM_mq8_nMEB0l',
    googleAnalyticsTagId: 'G-KT2S5WBQ40',
    googleMapApiKey: 'AIzaSyAA31DZzn3-uFVx0OeGeckRd9J-iUSoEJ0',
    googleAddressValidationApiKey: 'AIzaSyDQApRbIdo3F-Fd-wU_FDbxGJvLOJO8j7c',
    ...commonEnv,
    enableSentry: false,
};
