import { DynamicField, DynamicSections, Facility, PaymentDetailType } from '@cargos/sprintpay-models';
import { environment } from 'src/environments/environment';
import { Detail } from '../details';
import { PaymentRequest } from '../payments/payment-request.model';
import { UserProperties } from './user';

export class RhenusUser implements UserProperties {
    constructor(
        public dynamicFields: DynamicField[] = [],
        public dynamicSections: DynamicSections[] = []
    ) {
        const paymentURL = environment.uris.method.payments;
        const facilityURL = environment.uris.method.facilities;

        this.dynamicSections = [
            {
                id: 1,
                label: 'Rhenus Customer Reference',
                orderNumber: 0,
                company: { id: 57, name: 'Rhenus' },
                emailDomain: null,
                title: 'Rhenus Customer Reference',
            },
            {
                id: 2,
                label: 'Payment Details',
                orderNumber: 1,
                company: { id: 57, name: 'Rhenus' },
                emailDomain: null,
                title: 'Payment detail',
            },
            {
                id: 3,
                label: 'Customer Reference',
                orderNumber: 2,
                company: { id: 57, name: 'Rhenus' },
                emailDomain: null,
                title: 'Customer reference',
            },
            {
                id: 4,
                label: 'Payment Summary',
                orderNumber: 3,
                company: { id: 57, name: 'Emotrans' },
                emailDomain: null,
                title: 'Payment summary',
            },
        ];
        this.dynamicFields = [
            //Section 0
            {
                id: 1,
                name: 'paymentAmount',
                label: 'Payment Amount',
                section: 0,
                fieldType: 'dropdown',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                validations: [
                    {
                        id: 1,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                id: 2,
                name: 'locationId',
                label: 'Location',
                section: 0,
                fieldType: 'dropdown',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                apiProviderData: `${paymentURL}/customer/approval-flow/information`,
                provideData: true,
                validations: [
                    {
                        id: 1,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                id: 3,
                name: 'type',
                label: 'Type',
                section: 0,
                fieldType: 'dropdown',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                apiProviderData: `${facilityURL}/facilities/{{facilityId}}/details`,
                validations: [
                    {
                        id: 1,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
                options: [
                    {
                        value: 'Bill of Lading',
                        displayValue: 'Bill of Lading',
                    },
                    {
                        value: 'Breakdown fee',
                        displayValue: 'Breakdown fee',
                    },
                    {
                        value: 'Charter/ULD Bypass Fees',
                        displayValue: 'Charter/ULD Bypass Fees',
                    },
                    {
                        value: 'Import Service + Storage',
                        displayValue: 'Import Service + Storage',
                    },
                    {
                        value: 'Import Service Fee (ISC)',
                        displayValue: 'Import Service Fee (ISC)',
                    },
                    {
                        value: 'Ocean Freight',
                        displayValue: 'Ocean Freight',
                    },
                    {
                        value: 'Pallets/Wood skids',
                        displayValue: 'Pallets/Wood skids',
                    },
                    {
                        value: 'Storage/Demurrage/Detention',
                        displayValue: 'Storage/Demurrage/Detention',
                    },
                    {
                        value: 'Other',
                        displayValue: 'Other',
                    },
                ],
            },
            //section 1
            {
                id: 4,
                name: 'paymentType',
                label: `What's this payment for?`,
                section: 1,
                fieldType: 'dropdown',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                apiProviderData: `${facilityURL}/facilities/{{facilityId}}/details`,
                validations: [
                    {
                        id: 1,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
                options: [
                    {
                        value: 'Breakdown fee',
                        displayValue: 'Breakdown fee',
                    },
                    {
                        value: 'Delivery',
                        displayValue: 'Delivery',
                    },
                    {
                        value: 'ISC (Import Service Fee / CVF)',
                        displayValue: 'ISC (Import Service Fee / CVF)',
                    },
                    {
                        value: 'ISC & Storage',
                        displayValue: 'ISC & Storage',
                    },
                    {
                        value: 'Pallets/Wood skids',
                        displayValue: 'Pallets/Wood skids',
                    },
                    {
                        value: 'Storage',
                        displayValue: 'Storage',
                    },
                    {
                        value: 'Other',
                        displayValue: 'Other',
                    },
                ],
            },
            {
                id: 5,
                name: 'awb',
                label: '',
                section: 1,
                fieldType: 'input',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                validations: [
                    {
                        id: 1,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 2,
                        name: 'minlength',
                        value: '3',
                        message: 'Minimum of 3 digits',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 3,
                        name: 'preventedHTMLStr',
                        value: 'true',
                        message: 'HTML tags and/or URLs are not allowed here',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 4,
                        name: 'maxlength',
                        value: '255',
                        message: 'Maximum of 225 characters',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                id: 6,
                name: 'prefix',
                label: 'Prefix',
                section: 1,
                fieldType: 'autoComplete',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                mask: '000',
                validations: [
                    {
                        id: 1,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 2,
                        name: 'minlength',
                        value: '3',
                        message: 'Minimum of 3 digits',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 3,
                        name: 'pattern',
                        value: [/^[0-9]*$/],
                        message: 'We only allow numbers',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 4,
                        name: 'invalid',
                        value: '',
                        message: 'Prefix invalid',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                id: 7,
                name: 'number',
                label: '',
                section: 1,
                fieldType: 'input',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                mask: '00000000',
                hasExternalComponent: 'tooltip-customer-ref',
                validations: [
                    {
                        id: 1,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 2,
                        name: 'minlength',
                        value: '8',
                        message: 'Minimum of 8 digits',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 3,
                        name: 'maxlength',
                        value: '8',
                        message: 'Minimum of 8 digits',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 4,
                        name: 'awbValidator',
                        value: 'true',
                        message: 'Invalid awb',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                id: 8,
                name: 'amount',
                label: 'Amount to pay',
                placeholder: 'Enter - Amount to pay',
                section: 1,
                fieldType: 'input',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                mask: 'amount',
                validations: [
                    {
                        id: 1,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 2,
                        name: 'min',
                        value: '1.01',
                        message: `The minimum amount to pay it's 1.01`,
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                id: 9,
                name: 'hawb',
                label: 'Add one or more Hawb #',
                section: 1,
                fieldType: 'ChipGrid',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                validations: [
                    {
                        id: 1,
                        name: 'preventedHTMLStr',
                        value: 'true',
                        message: 'Invalid email',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                id: 10,
                name: 'nameFiles',
                label: 'Please attach the required document(s)',
                section: 1,
                fieldType: 'file',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel:
                    ' Choose a file or drag it here. You may upload at most 3 files .doc, .docx, .pdf or image (jpeg, jpg, png), of max 1MB each.',
                tooltipTitle: '',
                tooltipMessage: '',
                validations: [
                    {
                        id: 0,
                        name: 'required',
                        value: 'true',
                        message: 'Please attach the required document ',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 36,
                        name: 'accept',
                        value: '.docx,.doc,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, image/*',
                        message: 'Files',
                        title: null,
                        type: 'ATTRIBUTE',
                    },
                    {
                        id: 37,
                        name: 'maxfiles',
                        value: '3',
                        message: 'max files validations',
                        title: null,
                        type: 'CODE',
                    },
                    {
                        id: 38,
                        name: 'weight',
                        value: '1',
                        message: 'File weight',
                        title: null,
                        type: 'CODE',
                    },
                    {
                        id: 39,
                        name: 'multiple',
                        value: 'multiple',
                        message: 'multiple',
                        title: null,
                        type: 'ATTRIBUTE',
                    },
                ],
            },
            //Section 2
            {
                id: 11,
                name: 'customerRef',
                label: 'Ops file #',
                placeholder: 'Enter Ops file #',
                section: 2,
                fieldType: 'input',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage:
                    'Your own personal reference # so you can remember this payment. For example your file #.',
                validations: [
                    {
                        id: 0,
                        name: 'pattern',
                        value: [/^[0-9]{3,6}$/],
                        message: 'Enter a minimum of 3 characters and maximum of 6 numeric characters allowed.',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 1,
                        name: 'maxlength',
                        value: '255',
                        message: 'Maximum length exceeded (255 characters maximum)',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 2,
                        name: 'required',
                        value: 'true',
                        message: 'Please enter your Ops file #.',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 2,
                        name: 'required',
                        value: 'true',
                        message: 'Please enter your Ops file #.',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                id: 12,
                name: 'externalReferenceNum',
                label: 'Product',
                section: 2,
                fieldType: 'dropdown',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                options: [
                    {
                        value: 'Ocean',
                        displayValue: 'Ocean',
                    },
                    {
                        value: 'Air',
                        displayValue: 'Air',
                    },
                    {
                        value: 'Road',
                        displayValue: 'Road',
                    },
                ],
                validations: [
                    {
                        id: 0,
                        name: 'required',
                        value: 'true',
                        message: 'Please enter your Ops file #.',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                id: 13,
                name: 'email',
                label: 'Payment notification Email',
                placeholder: 'Enter the payment notification Email',
                section: 2,
                fieldType: 'input',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: '',
                tooltipMessage: '',
                validations: [
                    {
                        id: 0,
                        name: 'required',
                        value: 'true',
                        message: 'Required',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 1,
                        name: 'email',
                        value: 'true',
                        message: 'Invalid email',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 2,
                        name: 'minlength',
                        value: '5',
                        message: 'Invalid email',
                        title: null,
                        type: 'VALIDATION',
                    },
                    {
                        id: 3,
                        name: 'preventedHTMLStr',
                        value: 'true',
                        message: 'Invalid email',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
            {
                id: 14,
                name: 'notes',
                label: 'Notes (Optional)',
                placeholder: 'Add notes',
                section: 2,
                fieldType: 'textarea',
                company: undefined,
                emailDomain: null,
                isDisabled: false,
                isReplacement: false,
                isHidden: false,
                subLabel: '',
                tooltipTitle: 'icon',
                tooltipMessage: 'These notes are limited to 255 characters and are shared with the facility',
                validations: [
                    {
                        id: 1,
                        name: 'preventedHTMLStr',
                        value: 'true',
                        message: 'HTML tags and/or URLs are not allowed here',
                        title: null,
                        type: 'VALIDATION',
                    },
                ],
            },
        ];
    }

    public getPaymentDetails = (payment: PaymentDetailType, facility: Facility | null, id: string): Detail[] => [
        {
            label: facility?.externalLookup
                ? 'File Number'
                : facility?.awbFieldLabel === 'AWB'
                  ? 'RELATED BOL / AWB #'
                  : (facility?.awbFieldLabel ?? 'N/A'),
            value: payment?.prefix ? payment.prefix + '-' + payment?.number : payment?.awb ? payment.awb : 'N/A',
            show: (!facility?.externalLookup && !id) || !!id,
        },
        { label: 'Paid To', value: facility?.paidTo ?? 'N/A', show: true },
        {
            label: 'Amount',
            value: payment?.amount ? '$' + Number.parseFloat(payment.amount + '').toFixed(2) : 'N/A',
            show: (!facility?.externalLookup && !id) || !!id,
        },
        { label: 'HAWB/Container No.', value: payment?.hawb || 'N/A', show: !facility?.externalLookup },
    ];

    public getPaymentCustomerReference = (
        payment: PaymentDetailType,
        customerReferenceLabel: string = ''
    ): Detail[] => [
        {
            label: customerReferenceLabel ? customerReferenceLabel : 'Ops file #',
            value: payment.customerRef ? payment.customerRef : 'N/A',
            show: true,
        },
        {
            label: 'Payment notification Email',
            value: payment.email ? payment.email : 'N/A',
            show: true,
        },
        {
            label: 'Product',
            value: payment.externalReferenceNum ? payment.externalReferenceNum : 'N/A',
            show: true,
        },
        { label: 'Notes', value: payment.notes ? payment.notes : 'N/A', show: true },
    ];

    public getCompanyCustomerReference = (payment: PaymentDetailType): Detail[] => [
        {
            label: 'Payment Amount',
            value: (payment?.paymentAmount || payment?.externalData?.type) ?? 'N/A',
            show: !!((!payment?.externalLookup && !payment.id) || payment.id),
        },
        {
            label: 'Type',
            value: (payment?.type || payment?.paymentType) ?? 'N/A',
            show: true,
        },
        { label: 'Location', value: payment?.location ?? 'N/A', show: true },
    ];

    public getStandardRequestData = (payment: PaymentDetailType, facility: Facility): PaymentRequest => ({
        awb: payment.awb || '',
        amount: Number.parseFloat(payment['amount'].toString()),
        hawb: payment?.hawb || '',
        notes: payment?.notes || '',
        attachments: payment?.nameFiles || '',
        paymentType: payment?.paymentType || '',
        facility: facility?.id,
        notificationEmail: payment?.email || '',
        customerReference: payment?.customerRef || '',
        paidTo: facility.paidTo || 'test',
        id: payment.id,
        source: 'sprintpay',
    });

    public getRequestData = (payment: PaymentDetailType, facility: Facility, companyId: string): PaymentRequest => ({
        awb: payment?.awb,
        amount: Number.parseFloat(payment?.amount.toString()),
        hawb: payment?.hawb || '',
        notes: payment?.notes || '',
        attachments: payment?.nameFiles || '',
        paymentType: payment?.type || payment?.paymentType || '',
        facility: facility.id,
        notificationEmail: payment?.email || '',
        customerReference: payment?.customerRef || '',
        paidTo: facility?.paidTo || 'test',
        id: payment?.id,
        externalData: {
            type: payment?.paymentAmount || null,
            referenceNumber: payment?.customerRef || null,
            awbId: payment?.awb,
            externalReferenceNum: payment?.externalReferenceNum || null,
        },
    });
}
