<div id="facility_data" class="p-3 mb-0 mb-md-4 d-flex justify-content-between flex-column">
    <div>
        <label [class.facility-label]="facility?.externalLookup">Cargo Facility</label>
        <p class="mb-0">{{ formatAddress(facility)}}</p>
        <div class="d-flex">
            <img
                src="assets/images/eModal-logo.svg"
                class="me-2"
                alt="facility"
                width="108px"
                height="50px"
                *ngIf="isEnabledImg"
            />
            <h6 *ngIf="!isEnabledImg">{{facility?.name ?? ''}}</h6>
        </div>

        <span class="facilities-data--type d-block d-lg-none align-items-center h-100">
            <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'electronic'">
                <mat-icon class="me-2 align-middle">bolt</mat-icon>
                Electronic less than 1 hour
            </ng-container>
            <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'messenger'">
                <mat-icon class="me-2 align-middle">bolt</mat-icon>
                Messenger < 3 hour
            </ng-container>
            <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'fedex'">
                <mat-icon class="me-2 align-middle">bolt</mat-icon>
                FedEx = Next business day by 10:30 AM
            </ng-container>
        </span>
    </div>
    <span class="facilities-data--type d-none d-lg-flex align-items-center h-100">
        <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'electronic'">
            <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
            Electronic less than 1 hour
        </ng-container>
        <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'messenger'">
            <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
            Messenger < 3 hour
        </ng-container>
        <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'fedex'">
            <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
            FedEx = Next business day by 10:30 AM
        </ng-container>
    </span>
</div>
