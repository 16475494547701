import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// ROUTING

import { PasswordRoutingModule } from './password-routing.module';

//COMPONENTS

import { PasswordComponent } from './password.component';

//MODULE

@NgModule({
    declarations: [PasswordComponent],
    imports: [CommonModule, RouterModule, PasswordRoutingModule],
})
export class PasswordModule {
    constructor() {}
}
