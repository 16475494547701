import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// ROUTING

import { AppRoutingModule } from './app-routing.module';

// NGX PERMISSIONS
import { NgxMaskModule } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';

// HASH LOCATION RELOAD

import { LocationStrategy, PathLocationStrategy } from '@angular/common';

// COMPONENTS

import { AppComponent } from './app.component';

// INTERCEPTORS

import { HttpHeadersInterceptor } from './interceptors/http-headers.interceptor';
import { HttpUnauthorizedInterceptor } from './interceptors/http-unauthorized.interceptor';

// DIRECTIVES

import { CatchEventsModule } from './directives/catch-events.module';

//MODULE

import {
    LoggerErrorModule,
    SPRINTPAY_SERVICES_TOKEN,
    SprintPayAPIsModule,
    StatModule,
} from '@cargos/sprintpay-services';
import { PasswordModule } from './pages/auth/password/password.module';
import { TokenService } from './services/utils/token.service';

// Google Maps
import { GoogleMapsModule } from '@angular/google-maps';

// Google Analytics

import { environment } from 'src/environments/environment';
import { createGoogleAnalyticsConfig, GoogleAnalyticsModule } from './modules/google-analytics';
import { Config } from './utils/config';

// SERVICES

import { UrlService } from './services/url.service';
import { ApplicationErrorHandlerService } from './services/utils/application-error-handler.service';

let loggerErrorModuleProviders = LoggerErrorModule.forRoot({
    appSentryDsn: environment.appSentryDsn,
    appVersion: environment.appVersion,
    enableSentry: environment.enableSentry,
    environment: environment.environment,
}).providers;

loggerErrorModuleProviders = loggerErrorModuleProviders || [];

@NgModule({
    declarations: [AppComponent],
    imports: [
        CatchEventsModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxPermissionsModule.forRoot(),
        PasswordModule,
        NgxMaskModule.forRoot(),
        SprintPayAPIsModule.forRoot({
            environment: environment.environment,
            tokenGetter: {
                tokenProvider: {
                    provide: SPRINTPAY_SERVICES_TOKEN,
                    useFactory: tokenFactory,
                    deps: [TokenService],
                },
            },
            config: {
                apisWithoutHeaders: Config.apisWithoutHeader,
                apisWithoutContentType: Config.apisWithoutContentType,
                apisWithoutAccessControl: Config.apisWithoutAccessControl,
                allowedDomains: Config.allowedDomains,
                googleMapApiKey: environment.googleMapApiKey,
            },
        }),
        GoogleAnalyticsModule.forRoot(createGoogleAnalyticsConfig(environment)),
        GoogleMapsModule,
        StatModule.forRoot({
            sdkKey: environment.sdkKey,
            user: {},
            options: {
                environment: {
                    tier: environment.environment,
                },
            },
        }),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpUnauthorizedInterceptor, multi: true },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: ErrorHandler, useClass: ApplicationErrorHandlerService },
        ...loggerErrorModuleProviders,
        UrlService,
    ],
    exports: [HttpClientJsonpModule],
    bootstrap: [AppComponent],
})
export class AppModule {}

function tokenFactory(tokenService: TokenService): () => string | null {
    return () => tokenService.getCurrentUser();
}
