import { AddressAdapter } from '@cargos/sprintpay-models';
import { Countries } from 'src/app/models/utils/countries.model';
import { AccountTypeItem } from '../models/auth/signup.model';
import { sortPaymentTypes } from './ordered-data';

export const permissionsName: any = {
    ordinary: 'ORDINARY',
    lastApprover: 'LAST_APPROVER',
    schenkerUniqueRequestorApprover: 'Schenker_UNIQUE_REQUESTOR_APPROVER',
    schenkerRequestorApprover: 'Schenker_REQUESTOR_APPROVER',
    schenkerApprover: 'Schenker_APPROVER',
    schenkerRequestor: 'Schenker_REQUESTOR',
    knApprover: 'KN_APPROVER',
    knLastApprover: 'KN_LAST_APPROVER',
    knRequestor: 'KN_REQUESTOR',
    knFinancie: 'KN_FINANCIE',
    knRequestorApprover: 'KN_REQUESTOR_APPROVER',
    dhlRequestor: 'DHL_REQUESTOR',
    dhlLastApprover: 'DHL_LAST_APPROVER',
    dhlApprover: 'DHL_APPROVER',
    geodisRequestor: 'Geodis_REQUESTOR',
    geodisApprover: 'GEODIS_APPROVER',
    geodisFinancie: 'GEODIS_FINANCIE',
    geodisLastApprover: 'Geodis_LAST_APPROVER',
    emotransUniqueRequestor: 'Emotrans_UNIQUE_REQUESTOR',
    emotransUniqueRequestorApprover: 'Emotrans_UNIQUE_REQUESTOR_APPROVER',
    emotransLastApprover: 'Emotrans_LAST_APPROVER',
    rhenusUniqueRequestor: 'Rhenus_UNIQUE_REQUESTOR',
    rhenusUniqueRequestorApprover: 'Rhenus_UNIQUE_REQUESTOR_APPROVER',
    rhenusLastApprover: 'Rhenus_LAST_APPROVER',
    guest: 'GUEST',
};

export const sprintPaySource: string = 'sprintpay';
export const promoCodeValue: number = 8;

export const profileTypes = {
    FORWARDER: 'FORWARDER',
    FACILITY: 'FACILITY',
    FORWARDER_FACILITY: 'FORWARDER_FACILITY',
    FACILITY_PAYMENT: 'FACILITY_PAYMENT',
    FORWARDER_ADMIN: 'FORWARDER_ADMIN',
};

export const userTypes = {
    REQUESTOR: 'requestor',
    APPROVER: 'approver',
    FINANCIE: 'financie',
    UNIQUE_REQUESTOR: 'unique_requestor',
};

export const permissionsNameWithProfileType: any = {
    addUser: 'add-user',
    generateAch: 'generate-ach',
    viewAch: 'view-ach',
    editBankAccount: 'edit-bank-account',
    viewBankAccount: 'view-bank-account',
    submitRefunds: 'submit-refunds',
    viewRefunds: 'view-refunds',
    editPayments: 'edit-payments',
    viewPayments: 'view-payments',
    admin: 'admin',
    viewOnly: 'view-only',
    testPermission: 'test-permission',
    payments: 'payments',
    ach: 'ach',
    refundsRequests: 'refunds-requests',
};

export const paymentMethod: any = {
    CREDIT_CARD: 'Credit card',
    ECHECK: 'Echeck',
    PAYPAL: 'Paypal',
};

export const companyName: any = {
    schenker: 'Schenker',
    dhl: 'DHL',
    kn: 'KN',
    geodis: 'Geodis',
    emotrans: 'Emotrans',
    rhenus: 'Rhenus',
};

export const companyNameForCoda: any = {
    schenker: 'Schenker',
};

export const companyNamePendingApproval: any = {
    kn: companyName.kn,
    geodis: companyName.geodis,
};

export const profiles: any = {
    ACHDebit: 'We invoice customer then they pay us on our website via ACH debit',
};

export const regex: any = {
    email: /^(([^<>()\[\]\\.,;:\s@]+(\.[^<>()\[\]\\.,;:\s@]+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    isHTMLContent: /(<([^>]+)>)/gi,
    isUrlStr: /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
    schenkerVoucher: /^(5(\d{8}|\d{9})|6(\d{8}|\d{9})|9[0-5][0-9][0-9][0-9][0-9][0-9][0-9][0-9])$/,
    alphanumeric: /^[a-zA-Z0-9]*$/,
    numericDecimals: /^\d+(.\d+){0,2}$/,
    numeric: /^[0-9]*$/,
    alpha: /^[a-zA-Z]*$/,
};

export const customerReferenceDSV: RegExp[] = [
    /^(C|c)([a-zA-Z0-9]{8}|[a-zA-Z0-9]{9})$/,
    /^(S|s)([a-zA-Z0-9]{10}|[a-zA-Z0-9]{11})$/,
    /^(B|b)([a-zA-Z0-9]{10}|[a-zA-Z0-9]{11})$/,
];

export const mask: any = {
    card: '0000 0000 0000 0000',
    cardAmex: '0000 000000 00000',
    cvv: '000',
    cvvAmex: '0000',
    pcCA: [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/],
    pcUSA: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
};

export const permissionsSection: any = {
    cart: [
        permissionsName.ordinary,
        permissionsName.lastApprover,
        permissionsName.schenkerUniqueRequestorApprover,
        permissionsName.schenkerRequestorApprover,
        permissionsName.knLastApprover,
        permissionsName.dhlLastApprover,
    ],
    invoices: [
        permissionsName.ordinary,
        permissionsName.lastApprover,
        permissionsName.knLastApprover,
        permissionsName.dhlLastApprover,
        permissionsName.geodisRequestor,
        permissionsName.geodisLastApprover,
    ],
    paymentMethods: [
        permissionsName.lastApprover,
        permissionsName.schenkerUniqueRequestorApprover,
        permissionsName.schenkerRequestorApprover,
        permissionsName.knLastApprover,
        permissionsName.dhlLastApprover,
    ],
    pendingApprovals: [
        permissionsName.lastApprover,
        permissionsName.schenkerUniqueRequestorApprover,
        permissionsName.schenkerRequestorApprover,
        permissionsName.schenkerRequestor,
        permissionsName.schenkerApprover,
        permissionsName.knLastApprover,
        permissionsName.knRequestor,
        permissionsName.knRequestorApprover,
        permissionsName.knApprover,
        permissionsName.geodisRequestor,
        permissionsName.geodisLastApprover,
        permissionsName.dhlLastApprover,
    ],
    rejectedPayments: [
        permissionsName.lastApprover,
        permissionsName.schenkerUniqueRequestorApprover,
        permissionsName.schenkerRequestorApprover,
        permissionsName.schenkerRequestor,
        permissionsName.schenkerApprover,
        permissionsName.knLastApprover,
        permissionsName.knRequestor,
        permissionsName.knRequestorApprover,
        permissionsName.knApprover,
        permissionsName.geodisRequestor,
        permissionsName.geodisLastApprover,
        permissionsName.dhlLastApprover,
    ],
    voidedPayments: [
        permissionsName.lastApprover,
        permissionsName.schenkerUniqueRequestorApprover,
        permissionsName.schenkerRequestorApprover,
        permissionsName.schenkerRequestor,
        permissionsName.schenkerApprover,
        permissionsName.knLastApprover,
        permissionsName.knRequestor,
        permissionsName.knRequestorApprover,
        permissionsName.knApprover,
        permissionsName.geodisRequestor,
        permissionsName.geodisLastApprover,
        permissionsName.dhlLastApprover,
    ],
    // Super Admin Portal
    achDetails: [permissionsNameWithProfileType.viewAch],
};

export const profileComponents: any = {
    payments: 'payments',
    newPaymentButton: 'new-payment-button',
    newPayment: 'new-payment',
    paymentHistory: 'payment-history',
    paymentNotInvoiced: 'payment-not-invoiced',
    paymentPending: 'payment-pending',
    paymentToApprove: 'payment-to-approve',
    rejectedPayments: 'rejected-payments',
    pendingApproval: 'pending-approval',
    rejectedPaymentProfile: 'rejected-payment-profile',
    pendingPaymentProfile: 'pending-payment-profile',
    voidedPaymentProfile: 'voided-payment-profile',
    voidedPayemnts: 'voided-payments',
    paymentMethod: 'payment-method',
    creditCard: 'credit-card',
    echeck: 'echeck',
    cargoCredit: 'cargo-credit',
    invoice: 'invoice',
    invoiceOpen: 'invoice-open',
    addOpenInvoicesToCart: 'add-open-invoices-to-cart',
    addOpenInvoicesOfTableToCart: 'add-open-invoices-ofTable-to-cart',
    invoicePaid: 'invoice-paid',
    cart: 'cart',
    refundsRequests: 'refunds-requests',
    settings: 'settings',
    facilityReferences: 'facility-references',
    newPaymentInEmptyCart: 'new-payment-in-empty-cart',
    paypal: 'paypal',
    homeSPCredit: 'home-spcredit-component',
    homeCreditHold: 'home-credit-hold',
    sendRequestofTableToApprover: 'send-request-ofTable-to-approver',
    addRequestofTableToCart: 'add-request-ofTable-to-cart',
    uploadRequestFile: 'upload-request-file',
    uploadFileByPort: 'bulkUploadByPort',
    newPaymentFacilityReferences: 'new-payment-facility-references',
    automatedDebits: 'automated-debits',
    manageBankDetails: 'manage-bank-details',
    invoiceReports: 'invoice-reports',
    forwarderPaymentHistory: 'forwarder-payment-history',
    ach: 'ach',
    bankDebits: 'bank-debits',
    bankDebitsAdmin: 'bank-debits-admin',
    bankInfo: 'bank-info',
    debitHistory: 'debit-history',
    canNotCreatePayments: 'can-not-create-payments',
    home: 'home',
    users: 'users',
    facilityContacts: 'facility-contacts',
    autoPaySubscription: 'auto-pay-subscription',
};
//TODO Delete
export const paymentTypes = Object.freeze(
    sortPaymentTypes([
        'Breakdown fee',
        'Delivery',
        'ISC (Import Service Fee / CVF)',
        'ISC & Storage',
        'Pallets/Wood skids',
        'Storage',
        'Other',
    ])
);
//TODO Delete
export const paymentTypesDSV = Object.freeze(
    sortPaymentTypes([
        'AWB',
        'Bill of Lading',
        'Breakdown fee',
        'Demurrage',
        'Detention',
        'Drayage',
        'Freight Charge',
        'Handling',
        'Invoice',
        'Pallets/Wood skids',
        'Storage',
        'Terminal Fee',
        'Exam Charges',
        'Destination Charge',
    ])
);
export enum Domains {
    DSV = 'dsv.com',
}

export const paymentTypeRestrictionsForAttachFiles: Array<string> = ['Storage/Demurrage', 'ISC + Storage', 'Other***'];

export const stepsWorkflow = {
    facility: 'facility',
    to: 'to',
    customer: 'customer',
    detail: 'detail',
};

export const paginatorSize: Array<number> = [10, 25, 50, 100];

export const blackListFiles = [
    'pht',
    'sh',
    'bat',
    'sh',
    'psm1',
    'psd1',
    'ps1xml',
    'phtml',
    'php3',
    'php4',
    'php5',
    'php6',
    'inc',
    'jspx',
    'jspf',
    'jsw',
    'jsv',
    'php',
];

export const CW1 = /(S|C)\d{10}/;

export const MASK_CW1 = [/(S|C)/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const customerReference = {
    labelNotificationEmail: 'Payment notification email',
    labelNotificationEmailStartingLevel: 'Payment notification email',
    titleCustomerReference: 'Reference',
    placeholderCustomerReference: 'Enter your customer reference.',
};

export const tableCustomStatues = {
    REQUESTED: 'REQUESTED',
    APPROVED: 'APPROVED',
    FINALIZED: 'FINALIZED',
    DENIED: 'DENIED',
    REQUESTED_CARGO_FACILITY: 'REQUESTED_CARGO_FACILITY',
    REQUEST_CANCELED_BY_CUSTOMER: 'REQUEST_CANCELED_BY_CUSTOMER',
    IN_PROGRESS: 'IN_PROGRESS',
};

export const airportCodes: Array<string> = ['ATL', 'DFW', 'IAH', 'JFK', 'LAX', 'MIA', 'ORD', 'SEA', 'SFO', 'TPA'];

export const dynamicFieldTypes = {
    input: 'input',
    text: 'text',
    textbox: 'textbox',
    radioButton: 'radioButton',
    email: 'email',
    dropdown: 'dropdown',
    checkbox: 'checkbox',
    file: 'file',
};

export const downloadFormats = {
    CSV: 'CSV',
    XLSX: 'XLSX',
    PDF: 'PDF',
};

export const refundCancelReasons: Array<string> = ['Clerical error', 'Other'];

export const refundDeniedReasons: Array<string> = [
    'Charge(s) are valid',
    'Charges were directly refunded',
    'Duplicate refund request',
    'Other',
];

export const InitialCountries: Countries[] = [
    { alpha2Code: 'US', alpha3Code: 'USA', id: 189, isActive: true, name: 'United States', order: 1 },
    { alpha2Code: 'CA', alpha3Code: 'CAN', id: 221, isActive: true, name: 'Canada', order: 2 },
];

export const accountTypeItems: AccountTypeItem[] = [
    { name: 'Personal account', value: 0 },
    { name: 'Company account', value: 1 },
];

export const signupErrors = {
    DUPLICATED_EMAIL: 'ERROR_01',
    DUPLICATED_ACCOUNT_NAME: 'ERROR_02',
};

export const blackListedCountryCodes = [
    'AL',
    'BB',
    'BF',
    'BI',
    'BW',
    'BY',
    'BU',
    'CF',
    'CU',
    'HT',
    'IQ',
    'IR',
    'JM',
    'KH',
    'KP',
    'KY',
    'LB',
    'LY',
    'MA',
    'ML',
    'MT',
    'MU',
    'NI',
    'PK',
    'RU',
    'SD',
    'SN',
    'SO',
    'SS',
    'SY',
    'UA',
    'UG',
    'VE',
    'YE',
    'ZW',
    'CD',
    'CG',
    'MM',
];

export const GoogleAddressValidatorAdapter: AddressAdapter = {
    street: ['route'],
    street2: ['subpremise'],
    streetNumber: ['street_number'],
    city: ['locality'],
    state: ['administrative_area_level_1'],
    zipCode: ['postal_code'],
    country: ['country'],
};

export const termsOfUseURL = 'https://cargosprint.com/legal/terms-of-use/sprintpay';
export const cargosprintPhoneNumber = '678-470-4708';
