export class Companies {
    static readonly SCHENKER = 'schenker';
    static readonly DHL = 'dhl';
    static readonly KN = 'kn';
    static readonly GEODIS = 'geodis';
    static readonly EMOTRANS = 'emotrans';
    static readonly RHENUS = 'rhenus';
    static readonly DSV = 'dsv';
    static readonly EXPEDITORS = 'expeditors';

    static readonly COMPANIES_WITH_CONFIG_BY_DOMAIN = Object.freeze({
        [Companies.DSV]: 'dsv.com',
        [Companies.GEODIS]: 'geodis.com',
    });

    static readonly COMPANIES_WITH_STATIC_CONFIG: [
        typeof Companies.SCHENKER,
        typeof Companies.DHL,
        typeof Companies.KN,
        typeof Companies.GEODIS,
        typeof Companies.EMOTRANS,
        typeof Companies.RHENUS,
    ] = [Companies.SCHENKER, Companies.DHL, Companies.KN, Companies.GEODIS, Companies.EMOTRANS, Companies.RHENUS];
}

export type CompaniesWithStaticConfig = (typeof Companies.COMPANIES_WITH_STATIC_CONFIG)[number];
