import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationsType } from 'src/app/models/notifications/notifications';

@Injectable({
    providedIn: 'root',
})
export class BannerService {
    private notifications: BehaviorSubject<NotificationsType[]> = new BehaviorSubject<NotificationsType[]>([]);

    setNotifications(notifications: NotificationsType[]): void {
        this.notifications.next(notifications);
    }

    getNotifications(): Observable<NotificationsType[]> {
        return this.notifications.asObservable();
    }

    saveNotifications(notifications: NotificationsType[]): void {
        if (sessionStorage.getItem('banners') != undefined) {
            const currentNotifications: NotificationsType[] = JSON.parse(sessionStorage.getItem('banners') || '');
            const newNotifications: NotificationsType[] = [];
            // Add new notifications
            notifications.map((notification) => {
                const existNotification = !!currentNotifications.find(
                    (currentNotification) => currentNotification.name === notification.name
                );
                if (!existNotification) {
                    newNotifications.push(notification);
                }
            });
            // Delete notifications that doesn't exist in the new response
            currentNotifications.map((notification, index) => {
                const existNotification = !!notifications.find(
                    (newNotification) => newNotification.name === notification.name
                );
                if (!existNotification) {
                    currentNotifications.splice(index, 1);
                }
            });
            const updateNotifications = currentNotifications.concat(newNotifications);
            this.setNotifications(updateNotifications);
            sessionStorage.setItem('banners', JSON.stringify(updateNotifications));
        } else {
            sessionStorage.setItem('banners', JSON.stringify(notifications));
            this.setNotifications(notifications);
        }
    }

    updateNotification(updateNotification: NotificationsType): void {
        const currentNotifications: NotificationsType[] = JSON.parse(sessionStorage.getItem('banners') || '');
        const indexNotification = currentNotifications.findIndex(
            (notification) => notification.name === updateNotification.name
        );
        currentNotifications[indexNotification] = updateNotification;
        sessionStorage.setItem('banners', JSON.stringify(currentNotifications));
        this.setNotifications(currentNotifications);
    }
}
