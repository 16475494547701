import { ErrorHandler, Injectable, isDevMode } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable()
export class ApplicationErrorHandlerService implements ErrorHandler {
    constructor() {}

    /**
     * @method handleError()
     * @param (error: unknown)
     * @description
     */

    handleError(error: unknown): void {
        if (isDevMode()) {
            // eslint-disable-next-line no-console
            console.warn('Error occured:', error);
        }
        Swal.fire({
            title: 'Oops...',
            text: 'Something went wrong, please reload the page.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Close',
            confirmButtonColor: '#007E59',
            allowOutsideClick: false,
        });
    }
}
