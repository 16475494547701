import { Injectable } from '@angular/core';
import { DataSecurityService } from '../data-security.service';

export enum StorageTypes {
    SESSION = 'session',
    LOCAL = 'local',
}

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(private dataSecurityService: DataSecurityService) {}

    /**
     * @method cleanStorage()
     * @param (storageType?: string)
     * @description
     */

    cleanStorage(storageType?: string): void {
        if (storageType == StorageTypes.SESSION) {
            sessionStorage.clear();
        } else if (storageType == StorageTypes.LOCAL) {
            this.removeLocalStorage();
        } else {
            sessionStorage.clear();
            this.removeLocalStorage();
        }
    }

    removeLocalStorage(exclude: string[] = ['displayMessage']) {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ENVIRONMENT');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('accessControl');
        localStorage.removeItem('customerProfile');

        for (var i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);

            if (key && exclude.indexOf(key) === -1) {
                localStorage.removeItem(key || '');
            }
        }
    }

    /**
     * @method getElement()
     * @param (key: string)
     * @param (storageType?: string)
     * @description
     */

    getElement(key: string, storageType: string = StorageTypes.SESSION): string | null {
        if (storageType === StorageTypes.SESSION && sessionStorage.getItem(key) !== null) {
            return this.dataSecurityService.decryptString(sessionStorage.getItem(key)!);
        } else if (localStorage.getItem(key) !== null) {
            return this.dataSecurityService.decryptString(localStorage.getItem(key)!);
        }
        return null;
    }

    /**
     * @method removeElement()
     * @param (key: string)
     * @param (storageType?: string)
     * @description
     */

    removeElement(key: string, storageType: string = StorageTypes.SESSION): void {
        if (storageType === StorageTypes.SESSION) {
            sessionStorage.removeItem(key);
        } else if (storageType === StorageTypes.LOCAL) {
            localStorage.removeItem(key);
        } else {
            sessionStorage.removeItem(key);
            localStorage.removeItem(key);
        }
    }

    /**
     * @method saveElement()
     * @param (key: string)
     * @param (value: any)
     * @param (storageType?: string)
     * @description
     */

    saveElement<T>(key: string, value: T, storageType: string = StorageTypes.SESSION): void {
        if (storageType === StorageTypes.SESSION) {
            sessionStorage.setItem(key, this.dataSecurityService.encrypt(value));
        } else if (storageType === StorageTypes.LOCAL) {
            localStorage.setItem(key, this.dataSecurityService.encrypt(value));
        } else {
            sessionStorage.setItem(key, this.dataSecurityService.encrypt(value));
            localStorage.setItem(key, this.dataSecurityService.encrypt(value));
        }
    }
}
