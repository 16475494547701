import { Injectable } from '@angular/core';
import {
    DSVUser,
    DynamicCompany,
    EmotransUser,
    ExpeditorsUser,
    GeodisUser,
    Guest,
    KNUser,
    RhenusUser,
    SchenkerUser,
    User,
} from '../models/customer';
import { Companies } from '../models/utils/requester-flow-levels/companies';
import { CustomerService } from './utils/user';

@Injectable({
    providedIn: 'root',
})
export class CustomerConfiguration {
    constructor(private customerService: CustomerService) {}

    getCustomer(
        company?: string
    ):
        | DSVUser
        | Guest
        | SchenkerUser
        | GeodisUser
        | KNUser
        | EmotransUser
        | RhenusUser
        | ExpeditorsUser
        | DynamicCompany
        | User {
        if (this.customerService.isGuest()) {
            return new Guest();
        }
        switch (company) {
            case Companies.SCHENKER:
                return new SchenkerUser();
            case Companies.GEODIS:
                return new GeodisUser();
            case Companies.KN:
                return new KNUser();
            case Companies.EMOTRANS:
                return new EmotransUser();
            case Companies.RHENUS:
                return new RhenusUser();
            case Companies.EXPEDITORS:
                return new ExpeditorsUser();
            case Companies.DSV:
                return new DSVUser();
            default:
                return company ? new DynamicCompany() : User.fromJson({});
        }
    }
}
